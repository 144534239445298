import { Button, Col, Row, Typography } from 'antd'

const KYC = () => {
  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={5} style={{ marginBottom: 6 }}>
          KYC Verification
        </Typography.Title>
        <Typography.Text type="secondary">
          (Comming soon) Verify your identity and enjoy effortless whitelists of
          numerous projects in the Ancient8 ecosystem.
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Button type="primary" size="large" block disabled>
          Start Verifying
        </Button>
      </Col>
    </Row>
  )
}

export default KYC
