import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import { message, Spin, Tooltip } from 'antd'
import IonIcon from '@sentre/antd-ionicon'

import { AuthType } from 'libs/api/authType'
import { useAuthEntitiesByType } from 'hooks/useUser'
import { AppDispatch } from 'store'
import { makePrimaryAuthEntity } from 'store/user.reducer'

export type PrimaryProps = {
  type: AuthType
}

const Primary = ({ type }: PrimaryProps) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch<AppDispatch>()
  const authEntities = useAuthEntitiesByType(type) || []

  const { isPrimary, _id } =
    authEntities.length > 0 ? authEntities[0] : ({} as any)

  const onClick = useCallback(async () => {
    try {
      if (isPrimary || !_id) return
      setLoading(true)
      await dispatch(makePrimaryAuthEntity(_id)).unwrap()
      return message.success('You have changed to a new primary method!')
    } catch (er: any) {
      return message.error(er.message)
    } finally {
      return setLoading(false)
    }
  }, [dispatch, isPrimary, _id])

  return (
    <Tooltip title="Primary is the available method for login. You cannot to remove the primary but you can change it.">
      {loading ? (
        <Spin size="small" />
      ) : (
        <IonIcon
          name="star"
          style={{
            cursor: 'pointer',
            color: isPrimary ? '#2eb835' : 'inherit',
          }}
          onClick={onClick}
        />
      )}
    </Tooltip>
  )
}

export default Primary
