import {
  CSSProperties,
  MouseEvent,
  ReactNode,
  useCallback,
  useState,
} from 'react'
import copy from 'copy-to-clipboard'

import { Tooltip, Button } from 'antd'
import IonIcon from '@sentre/antd-ionicon'

import { asyncWait } from 'libs/util'

export type ClipboardProps = {
  content: string
  icon?: ReactNode
  title?: string
  style?: CSSProperties
}

const Clipboard = ({
  content,
  icon = <IonIcon name="copy-outline" />,
  title = '',
  style = {},
}: ClipboardProps) => {
  const [copied, setCopied] = useState(false)

  const onCopy = useCallback(
    async (value: string, e: MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      copy(value)
      setCopied(true)
      await asyncWait(1500)
      return setCopied(false)
    },
    [],
  )

  return (
    <Tooltip title="Copied" open={copied}>
      <Button
        type="link"
        size="small"
        onClick={(e) => onCopy(content, e)}
        icon={icon}
        style={style}
      >
        {title}
      </Button>
    </Tooltip>
  )
}

export default Clipboard
