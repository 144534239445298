import { Col, Row, Tabs } from 'antd'
import General from './general'
import OAuth from './oauth'
import Settings from './settings'

const ITEMS = [
  {
    label: 'Profile',
    key: 'profile',
    children: <General />,
  },
  {
    label: 'OAuth',
    key: 'oauth',
    children: <OAuth />,
  },
  {
    label: 'Settings',
    key: 'settings',
    children: <Settings />,
  },
]

const Profile = () => {
  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Tabs items={ITEMS} defaultActiveKey="profile" />
      </Col>
    </Row>
  )
}

export default Profile
