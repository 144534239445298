import { Col, Row, Space, Typography } from 'antd'
import AddWalletButton from './addWalletButton'
import EvmChain from './evmChain'
import SolChain from './solChain'

const WalletList = () => {
  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Row gutter={[12, 12]} wrap={false}>
          <Col flex="auto">
            <Typography.Title level={5}>Wallet List</Typography.Title>
          </Col>
          <Col>
            <AddWalletButton />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <SolChain />
          <EvmChain />
        </Space>
      </Col>
    </Row>
  )
}

export default WalletList
