import { Fragment, useCallback, useState } from 'react'

import { Button, Col, Modal, Row } from 'antd'
import IonIcon from '@sentre/antd-ionicon'
import { VirtualRoute } from 'components/virtualRoute'
import Home from './home'
import SolanaWallet from './solanaWallet'
import EvmWallet from './evmWallet'

import { useVirtualNavigation } from 'hooks/useVirtualRoute'

const AddWalletButton = () => {
  const [visible, setVisible] = useState(false)
  const navigate = useVirtualNavigation()

  const onClose = useCallback(() => {
    navigate('/')
    return setVisible(false)
  }, [navigate])

  return (
    <Fragment>
      <Button
        type="link"
        size="small"
        icon={<IonIcon name="add-circle" />}
        onClick={() => setVisible(true)}
      >
        Add Wallet
      </Button>
      <Modal
        open={visible}
        footer={null}
        closeIcon={<IonIcon name="close" />}
        onCancel={onClose}
        width={375}
        centered
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <VirtualRoute exact route="/">
              <Home />
            </VirtualRoute>
            <VirtualRoute exact route="/solana">
              <SolanaWallet onClose={onClose} />
            </VirtualRoute>
            <VirtualRoute exact route="/evm">
              <EvmWallet onClose={onClose} />
            </VirtualRoute>
          </Col>
          <Col span={24} />
        </Row>
      </Modal>
    </Fragment>
  )
}

export default AddWalletButton
