import { Col, Modal, Row } from 'antd'
import { VirtualRoute } from 'components/virtualRoute'
import ConnectHome from './home'
import SolanaAuth from './solanaAuth'
import EvmAuth from './evmAuth'
import EmailAuth from './emailAuth'

export type ConnectProps = {
  open?: boolean
  onCancel?: () => void
}

const Connect = ({ open = false, onCancel = () => {} }: ConnectProps) => {
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      footer={null}
      width={375}
      centered
      closable={false}
    >
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <VirtualRoute exact route="/">
            <ConnectHome />
          </VirtualRoute>
          <VirtualRoute route="/solana">
            <SolanaAuth />
          </VirtualRoute>
          <VirtualRoute route="/evm">
            <EvmAuth />
          </VirtualRoute>
          <VirtualRoute route="/email">
            <EmailAuth />
          </VirtualRoute>
        </Col>
      </Row>
    </Modal>
  )
}

export default Connect
