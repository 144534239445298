import { useCallback, useEffect, useState } from 'react'
import moment from 'moment'

import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Row,
  Space,
  Typography,
} from 'antd'
import IonIcon from '@sentre/antd-ionicon'

import { useQueryParam } from 'hooks/useQueryParam'
import { getClientInfo } from 'libs/api/client'
import { useLogout, useUserId } from 'hooks/useUser'
import { shortenString } from 'libs/util'
import { authorizeCode } from 'libs/api/oauth'

const OAuth = () => {
  const clientKey = useQueryParam('clientKey')
  const redirectUri = useQueryParam('redirectUri')
  const state = useQueryParam('state')
  const [clientName, setClientName] = useState('')
  const [clientAvatar, setClientAvatar] = useState('')
  const [error, setError] = useState('')
  const userId = useUserId()
  const logout = useLogout()

  const onCancel = useCallback(() => {
    window.location.href = `${redirectUri}?error=${encodeURIComponent(
      'The request has been rejected',
    )}`
  }, [redirectUri])

  const onGoBack = useCallback(() => {
    if (redirectUri) return onCancel()
    return window.history.back()
  }, [redirectUri, onCancel])

  const onAuthorize = useCallback(async () => {
    try {
      if (!clientKey) throw new Error('Invalid client key')
      if (!redirectUri) throw new Error('Invalid redirect uri')
      const { code } = await authorizeCode(clientKey)
      window.location.href = `${redirectUri}?code=${code}&state=${state}`
      return setError('')
    } catch (er: any) {
      return setError(er.message)
    }
  }, [clientKey, redirectUri, state])

  const fetchClientInfo = useCallback(async () => {
    try {
      if (!clientKey) throw new Error('Invalid client key')
      const {
        clientName,
        avatar,
        redirectUri: expectedRedirectUri,
      } = await getClientInfo(clientKey)
      if (!redirectUri || redirectUri !== expectedRedirectUri)
        throw new Error('Invalid redirect uri')
      setClientAvatar(avatar)
      setClientName(clientName)
      return setError('')
    } catch (er: any) {
      return setError(er.message)
    }
  }, [clientKey, redirectUri])

  useEffect(() => {
    fetchClientInfo()
  }, [fetchClientInfo])

  return (
    <Row gutter={[24, 24]} justify="center">
      <Col>
        <Card bodyStyle={{ width: 375 }}>
          {error ? (
            <Row gutter={[24, 24]} justify="center">
              <Col>
                <Space direction="vertical" style={{ textAlign: 'center' }}>
                  <Typography.Title type="danger" level={1}>
                    <IonIcon name="warning-outline" />
                  </Typography.Title>
                  <Typography.Text type="secondary" className="caption">
                    An error has been occurred:
                  </Typography.Text>
                  <Typography.Text>{error}</Typography.Text>
                </Space>
              </Col>
              <Col span={24}>
                <Button type="primary" size="large" onClick={onGoBack} block>
                  Go Back
                </Button>
              </Col>
            </Row>
          ) : (
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[12, 12]} justify="center">
                  <Col>
                    <Space direction="vertical" style={{ textAlign: 'center' }}>
                      <Typography.Text type="secondary" className="caption">
                        The application
                      </Typography.Text>
                      <Typography.Title level={4}>
                        {clientName}
                      </Typography.Title>
                      <Avatar src={clientAvatar} shape="square" size={64}>
                        <IonIcon name="earth-outline" />
                      </Avatar>
                      <Typography.Text type="secondary" className="caption">
                        wants to access your Ancient8 profile.
                      </Typography.Text>
                    </Space>
                  </Col>
                  <Col>
                    <Space size={0}>
                      <Typography.Text type="secondary">
                        Signed in as {shortenString(userId || '')}.
                      </Typography.Text>
                      <Button size="small" type="link" onClick={logout}>
                        Not you?
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Divider style={{ margin: 0 }} />
              </Col>
              <Col span={24}>
                <Typography.Paragraph style={{ textTransform: 'uppercase' }}>
                  This will allow the application {clientName} to access:
                </Typography.Paragraph>
                <Space direction="vertical" size={0}>
                  <Space>
                    <Button
                      type="link"
                      icon={<IonIcon name="checkmark-circle" />}
                    />
                    <Typography.Text>Read your personal data.</Typography.Text>
                  </Space>
                  <Space>
                    <Button
                      type="text"
                      icon={<IonIcon name="close-circle" />}
                    />
                    <Typography.Text>
                      Modify your personal data.
                    </Typography.Text>
                  </Space>
                </Space>
              </Col>
              <Col span={24}>
                <Divider style={{ margin: 0 }} />
              </Col>
              <Col span={24}>
                <Typography.Paragraph type="secondary" className="caption">
                  <IonIcon name="link-outline" style={{ marginRight: 8 }} />
                  Once you authorize, you will be redirected to: {redirectUri}.
                </Typography.Paragraph>
                <Typography.Paragraph type="secondary" className="caption">
                  <IonIcon name="time-outline" style={{ marginRight: 8 }} />
                  Activated since {moment().format('MMM Do YY')}.
                </Typography.Paragraph>
              </Col>
              <Col span={12}>
                <Button size="large" onClick={onCancel} block>
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Button type="primary" size="large" onClick={onAuthorize} block>
                  Authorize
                </Button>
              </Col>
            </Row>
          )}
        </Card>
      </Col>
    </Row>
  )
}

export default OAuth
