import axios from 'axios'

import configs from 'configs'

const { api } = configs

/**
 * Validate user username has existed
 * @param username
 * True if existing, otherwise False
 */
export const validateUsername = async (username: string) => {
  try {
    await axios.post(
      `${api.user}/validate/username/${encodeURIComponent(username)}`,
      {},
    )
    return false
  } catch (er) {
    return true
  }
}
