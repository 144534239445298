import IonIcon from '@sentre/antd-ionicon'
import { Button } from 'antd'

import './index.less'

type TextRouterLinkProps = {
  title: string
  url?: string
}

export const TextRouterLink = ({ title, url = '#' }: TextRouterLinkProps) => {
  return (
    <Button className="router-link" type="link" href={url} target="_blank">
      {title}
    </Button>
  )
}

type IconRouterLinkProps = {
  iconName: string
  url?: string
}

export const IconRouterLink = ({
  iconName,
  url = '#',
}: IconRouterLinkProps) => {
  return (
    <Button
      className="router-link"
      icon={<IonIcon name={iconName} />}
      type="link"
      size="large"
      href={url}
      target="_blank"
    />
  )
}
