import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { Button, Col, Row, Typography } from 'antd'
import { Ancient8Logo } from 'components/logos'

import { useUserId } from 'hooks/useUser'
import { AppDispatch } from 'store'
import { setVisibleConnect } from 'store/ui.reducer'
import { useQueryParam } from 'hooks/useQueryParam'

const Welcome = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const userId = useUserId()
  const dispatch = useDispatch<AppDispatch>()
  const openWallet = useQueryParam('openWallet')

  const onExplore = useCallback(() => {
    return dispatch(setVisibleConnect(true))
  }, [dispatch])

  // Redirect callback
  const handleRedirect = useCallback(async () => {
    const params = new URLSearchParams(search)
    const redirect = decodeURIComponent(params.get('redirect') || '/profile')
    if (userId) {
      await dispatch(setVisibleConnect(false))
      return navigate(redirect)
    }
    if (openWallet === 'true') return dispatch(setVisibleConnect(true))
  }, [userId, navigate, search, openWallet, dispatch])

  useEffect(() => {
    handleRedirect()
  }, [handleRedirect])

  return (
    <Row gutter={[24, 24]} justify="center">
      <Col style={{ marginTop: 64 }}>
        <Ancient8Logo width={256} />
      </Col>
      <Col span={24}>
        <Typography.Title level={1} style={{ textAlign: 'center' }}>
          Welcome to Ancient8 ID
        </Typography.Title>
      </Col>
      <Col style={{ marginBottom: 128 }}>
        <Button type="primary" size="large" onClick={onExplore}>
          Explore Ancient8
        </Button>
      </Col>
    </Row>
  )
}

export default Welcome
