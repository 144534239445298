import { useCallback } from 'react'

import { Button, Col, Input, Row, Space, Typography } from 'antd'
import IonIcon from '@sentre/antd-ionicon'
import EmailAuthTitle from './title'

import { useVirtualNavigation } from 'hooks/useVirtualRoute'
import { useCountdown } from 'hooks/useCountdown'

export type EmailAuthVerificationProps = {
  email: string
  token: string
  onToken: (token: string) => void
  onLogin: () => void
  onContinue: () => void
  loading?: boolean
}

const EmailAuthVerification = ({
  email,
  token,
  onToken,
  onLogin,
  onContinue,
  loading = false,
}: EmailAuthVerificationProps) => {
  const { counter, refresh } = useCountdown(60)
  const navigate = useVirtualNavigation()

  const onEdit = useCallback(() => navigate('/email/login'), [navigate])
  const onResend = useCallback(() => {
    refresh()
    return onContinue()
  }, [onContinue, refresh])

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <EmailAuthTitle title="VERIFY YOUR EMAIL" />
      </Col>
      <Col span={24} />
      <Col span={24}>
        <Row gutter={[12, 12]} justify="center">
          <Col>
            <Space direction="vertical" align="center" size={0}>
              <Typography.Text>A 6-digit code has been sent to</Typography.Text>
              <Button type="link" onClick={onEdit}>
                <Space>
                  {email}
                  <IonIcon name="create" />
                </Space>
              </Button>
            </Space>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[8, 8]} justify="center">
          <Col span={24}>
            <Input
              placeholder="Enter OTP"
              size="large"
              style={{ textAlign: 'center' }}
              value={token}
              onChange={(e) => onToken(e.target.value)}
              autoFocus
            />
          </Col>
          <Col>
            {counter ? (
              <Space>
                <Typography.Text>The OTP will expire in</Typography.Text>
                <Typography.Text className="primary">
                  {counter} secs
                </Typography.Text>
              </Space>
            ) : (
              <Typography.Text type="danger">
                The OTP is expired.
              </Typography.Text>
            )}
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Button
          type="primary"
          size="large"
          onClick={onLogin}
          disabled={!token}
          loading={loading}
          block
        >
          Log In
        </Button>
      </Col>
      <Col span={24} style={{ height: 24 }} />
      <Col span={24}>
        <Row gutter={[12, 12]} justify="center">
          <Col>
            <Space direction="vertical" align="center" size={0}>
              <Typography.Text>Didn't receive the code?</Typography.Text>
              <Button
                type="link"
                icon={<IonIcon name="sync-outline" />}
                onClick={onResend}
                disabled={counter > 0}
              >
                Resend
              </Button>
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default EmailAuthVerification
