import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import IonIcon from '@sentre/antd-ionicon'
import { Button, message, Popconfirm } from 'antd'

import { AppDispatch } from 'store'
import { deleteAuthEntity } from 'store/user.reducer'
import { useAuthEntityById } from 'hooks/useUser'

export type RemoveButtonProps = {
  authEntityId?: string
}

const RemoveButton = ({ authEntityId = '' }: RemoveButtonProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const { isPrimary } = useAuthEntityById(authEntityId) || {}

  const onRemove = useCallback(async () => {
    try {
      if (!authEntityId) return
      setLoading(true)
      await dispatch(deleteAuthEntity(authEntityId)).unwrap()
      return message.success('You have removed the method successfully!')
    } catch (er: any) {
      return message.error(er.message)
    } finally {
      return setLoading(false)
    }
  }, [authEntityId, dispatch])

  return (
    <Popconfirm
      open={visible}
      placement="left"
      title="Are you sure to delete this method?"
      onConfirm={onRemove}
      onCancel={() => setVisible(false)}
    >
      <Button
        type="text"
        size="small"
        icon={<IonIcon name="trash-outline" />}
        style={{ marginRight: -7 }}
        onClick={() => setVisible(true)}
        loading={loading}
        disabled={isPrimary}
        danger
      />
    </Popconfirm>
  )
}

export default RemoveButton
