import { useCallback, useState } from 'react'

import { Col, message, Row } from 'antd'
import { VirtualRoute, VirtualRedirect } from 'components/virtualRoute'
import Navigation from '../navigaton'
import EmailAuthLogin from './login'
import EmailAuthVerification from './verification'

import { useVirtualNavigation } from 'hooks/useVirtualRoute'
import {
  challengeEmailOtpForSignup,
  signupEmailOtp,
  challengeEmailOtpForLogin,
  loginEmailOtp,
  validateEmail,
} from 'libs/api/emailAuth'
import { useCloseConnect } from 'hooks/useUi'
import { AuthType } from 'libs/api/authType'
import { useLogin } from 'hooks/useUser'

const EmailAuth = () => {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [token, setToken] = useState('')
  const navigate = useVirtualNavigation()
  const onCloseConnect = useCloseConnect()
  const login = useLogin()

  const onContinue = useCallback(async () => {
    try {
      setLoading(true)
      setToken('')
      const existed = await validateEmail(email)
      const challenge = existed
        ? challengeEmailOtpForLogin
        : challengeEmailOtpForSignup
      await challenge(email)
      return navigate('/email/verification')
    } catch (er: any) {
      return message.error(er.message)
    } finally {
      return setLoading(false)
    }
  }, [email, navigate])

  const onLogin = useCallback(async () => {
    try {
      setLoading(true)
      const existed = await validateEmail(email)
      const validate = existed ? loginEmailOtp : signupEmailOtp
      const data = await validate({ type: AuthType.EmailOTP, token, email })
      await login(data)
      return onCloseConnect()
    } catch (er) {
      return message.error('Invalid OTP Code')
    } finally {
      return setLoading(false)
    }
  }, [token, email, onCloseConnect, login])

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Navigation title="LOG IN" />
      </Col>
      <Col span={24}>
        <VirtualRedirect from="/email" to="/email/login" />
        <VirtualRoute exact route="/email/login">
          <EmailAuthLogin
            email={email}
            onEmail={setEmail}
            onContinue={onContinue}
            loading={loading}
          />
        </VirtualRoute>
        <VirtualRoute exact route="/email/verification">
          <EmailAuthVerification
            email={email}
            token={token}
            onToken={setToken}
            onLogin={onLogin}
            onContinue={onContinue}
            loading={loading}
          />
        </VirtualRoute>
      </Col>
    </Row>
  )
}

export default EmailAuth
