import axios from 'axios'

import configs from 'configs'
import { getAuthorizationHeader } from 'libs/api/accessToken'

const { api } = configs

/**
 * Authorize code
 * @param clientKey
 * @returns
 */
export const authorizeCode = async (clientKey: string) => {
  const { data } = await axios.post(
    `${api.auth}/authorize-code`,
    { clientKey },
    {
      headers: getAuthorizationHeader(),
    },
  )
  return data
}
