import icon from './assets/metamask.png'
import AdapterInterface, { Chain } from './adapter.interface'

class MetamaskEvmAdapter implements AdapterInterface {
  // Private provider
  private provider = window.ethereum
  // Public params
  public name = 'Metamask'
  public chain = Chain.evm
  public icon = icon
  public url = 'https://metamask.io'
  public color = 'linear-gradient(90deg, rgb(232 128 7) 0%, rgb(148 82 5) 100%)'
  public isInstalled = window.ethereum?.isMetaMask
  // Public functions
  public connect = async () => {
    await this.provider.request({ method: 'eth_requestAccounts' })
  }
  public install = () => window.open(this.url, '_blank')
  public signMessage = async (msg: string) => {
    const address = await this.getAddress()
    const hex = `0x${Buffer.from(msg, 'utf8').toString('hex')}`
    const sig = await this.provider.request({
      method: 'personal_sign',
      params: [hex, address.toLowerCase()],
    })
    return sig
  }
  public getAddress = async () => {
    const [address] = await this.provider.request({
      method: 'eth_requestAccounts',
    })
    if (!address) throw new Error('Invalid address')
    return address
  }
  public disconnect = () => {}
}

export default MetamaskEvmAdapter
