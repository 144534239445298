import { Col, Row, Space, Typography } from 'antd'
import IonIcon from '@sentre/antd-ionicon'

export type EmailAuthTitleProps = { title: string }

const EmailAuthTitle = ({ title }: EmailAuthTitleProps) => {
  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Row gutter={[12, 12]} justify="center">
          <Col>
            <Space direction="vertical" align="center">
              <IonIcon style={{ fontSize: 48 }} name="mail-outline" />
              <Typography.Title level={4}>{title}</Typography.Title>
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default EmailAuthTitle
