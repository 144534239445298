import { configureStore } from '@reduxjs/toolkit'
import { env } from 'configs/env'

import ui from './ui.reducer'
import virtualRoute from './virtualRoute.reducer'
import user from './user.reducer'
import client from './client.reducer'

/**
 * Root types
 */
export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

/**
 * Root store
 */
const store = configureStore({
  devTools: env === 'development' ? { name: 'a8-auth' } : false,
  reducer: {
    ui,
    virtualRoute,
    user,
    client,
  },
})

export default store
