/**
 * This module is simulating a simple routing mechanism
 * The routing state is managed in Redux and other components are able to use it interactively.
 * For Example:
 *  The Connect modal wil have a complex state machine and multiple branched choices.
 *  However, with virtual routes, everything now is clear and working like a browser routing
 */

import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppDispatch, AppState } from 'store'
import { push } from 'store/virtualRoute.reducer'

/**
 * Watch the current pathname
 * @returns
 */
export const usePath = () => {
  const path = useSelector(({ virtualRoute }: AppState) => virtualRoute.path)
  return path
}

/**
 * Watch & Parse the query params
 * @returns
 */
export const useQuery = () => {
  const query = useSelector(({ virtualRoute }: AppState) => virtualRoute.query)
  const search = new URLSearchParams(query)
  const keys = Array.from(search.keys())
  let re: Record<string, string> = {}
  keys.forEach((key) => (re[key] = search.get(key) as string))
  return re
}

/**
 * Whether backable
 * @returns
 */
export const useBackable = () => {
  const index = useSelector(({ virtualRoute }: AppState) => virtualRoute.index)
  return index > 0
}

/**
 * Whether forwardable
 * @returns
 */
export const useForwardable = () => {
  const index = useSelector(({ virtualRoute }: AppState) => virtualRoute.index)
  const history = useSelector(
    ({ virtualRoute }: AppState) => virtualRoute.history,
  )
  return index < history.length - 1
}

/**
 * Return navigate callback
 * @returns
 */
export const useVirtualNavigation = () => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useCallback((to: string) => dispatch(push(to)), [dispatch])
  return navigate
}
