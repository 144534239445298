import { useCallback, useState } from 'react'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload'

import { Avatar, Button, message, Upload } from 'antd'
import IonIcon from '@sentre/antd-ionicon'

import { useFetchProfile, useUserAvatar } from 'hooks/useUser'
import { updateProfileAvatar } from 'libs/api/profile'

const UserAvatar = () => {
  const [loading, setLoading] = useState(false)
  const avatar = useUserAvatar()
  const fetchProfile = useFetchProfile()

  const onUpload = useCallback(
    async ({ fileList: [file] }: UploadChangeParam<UploadFile<File>>) => {
      try {
        setLoading(true)
        if (!file.originFileObj || !file.size || file.size > 1000000)
          throw new Error(
            'The profile image must be a file of image type with maximum size of 1MB.',
          )
        await updateProfileAvatar(file.originFileObj)
        return fetchProfile()
      } catch (er: any) {
        return message.error(er.message)
      } finally {
        return setLoading(false)
      }
    },
    [fetchProfile],
  )

  return (
    <span>
      <Avatar size={128} src={avatar} />
      <Upload
        onChange={onUpload}
        accept="image/*"
        showUploadList={false}
        maxCount={1}
        beforeUpload={() => false}
      >
        <Button
          type="primary"
          shape="circle"
          icon={<IonIcon name="camera" />}
          style={{ position: 'absolute', bottom: 8, right: 8 }}
          loading={loading}
        />
      </Upload>
    </span>
  )
}

export default UserAvatar
