import { Env } from './env'

/**
 * Contructor
 */

type Conf = {
  origin: string
  auth: string
  user: string
  client: string
}

const generator = (origin: string): Conf => ({
  origin,
  auth: origin + '/auth',
  user: origin + '/user',
  client: origin + '/client',
})

const conf: Record<Env, Conf> = {
  /**
   * Development configurations
   */
  development: {
    ...generator('https://id-api-dev.ancient8.gg/api'),
  },

  /**
   * Development configurations
   */
  staging: {
    ...generator('https://id-api-dev.ancient8.gg/api'),
  },

  /**
   * Production configurations
   */
  production: {
    ...generator('https://id-api.ancient8.gg/api'),
  },
}

/**
 * Module exports
 */
export default conf
