import { useCallback } from 'react'

import { Button, Col, Row, Typography } from 'antd'
import Navigation from '../navigaton'
import { EvmLogo } from 'components/logos'
import { WalletButton } from 'components/baseButton'

import { isEthereumAddress } from 'libs/util'
import {
  challengeWallet,
  loginWallet,
  signupWallet,
  validateWallet,
} from 'libs/api/walletAuth'
import { AuthType } from 'libs/api/authType'
import { useCloseConnect } from 'hooks/useUi'
import { useEvmWalletProviders } from 'hooks/useWalletProviders'
import { useLogin } from 'hooks/useUser'

const EvmAuth = () => {
  const onCloseConnect = useCloseConnect()
  const providers = useEvmWalletProviders()
  const login = useLogin()

  const onConnect = useCallback(
    async (type: keyof typeof providers) => {
      const provider = providers[type]
      if (!provider.isInstalled) return window.open(provider.url, '_blank')
      await provider.connect()
      const walletAddress = await provider.getAddress()
      if (!isEthereumAddress(walletAddress)) throw new Error('Invalid address')
      const { _id, message } = await challengeWallet(walletAddress)
      const signature = await provider.signMessage(message)
      const payload = {
        type: AuthType.EVMChain,
        credential: {
          authChallengeId: _id,
          walletAddress: walletAddress,
          signedData: signature,
        },
      }
      const existed = await validateWallet(walletAddress)
      const validate = existed ? loginWallet : signupWallet
      const data = await validate(payload)
      await login(data)
      return onCloseConnect()
    },
    [onCloseConnect, providers, login],
  )

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Navigation title="EVM" />
      </Col>
      <Col span={24}>
        <Row gutter={[12, 12]} justify="center">
          <Col>
            <EvmLogo width={256} />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Typography.Title
          className="primary"
          level={4}
          style={{ textAlign: 'center' }}
        >
          Select wallet
        </Typography.Title>
      </Col>
      <Col span={24} />
      <Col span={24}>
        <Row gutter={[12, 12]}>
          {(Object.keys(providers) as Array<keyof typeof providers>).map(
            (key) => {
              const { url, name, icon, color, isInstalled } = providers[key]
              return (
                <Col span={24} key={key}>
                  <WalletButton
                    title={name}
                    subtitle={url + (!isInstalled ? ' (not installed)' : '')}
                    icon={icon}
                    color={color}
                    onClick={() => onConnect(key)}
                  />
                </Col>
              )
            },
          )}
        </Row>
      </Col>
      <Col span={24} style={{ height: 24 }} />
      <Col span={24}>
        <Button type="link" disabled block>
          Lost your wallet?
        </Button>
      </Col>
    </Row>
  )
}

export default EvmAuth
