import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppDispatch, AppState } from 'store'
import { setVisibleConnect } from 'store/ui.reducer'
import { useVirtualNavigation } from './useVirtualRoute'

/**
 * Watch window inner width
 * @returns
 */
export const useWidth = () => {
  const width = useSelector(({ ui }: AppState) => ui.width)
  return width
}

/**
 * Watch window infix. The scale is follow Ant Design standard
 * @returns
 */
export const useInfix = () => {
  const infix = useSelector(({ ui }: AppState) => ui.infix)
  return infix
}

/**
 * Watch Connect modal visible
 * @returns
 */
export const useVisibleConnect = () => {
  const visibleConnect = useSelector(({ ui }: AppState) => ui.visibleConnect)
  return visibleConnect
}

/**
 * Return openConnect callback
 * @returns
 */
export const useOpenConnect = () => {
  const dispatch = useDispatch<AppDispatch>()
  const onOpenConnect = useCallback(
    () => dispatch(setVisibleConnect(true)),
    [dispatch],
  )
  return onOpenConnect
}

/**
 * Return closeConnect callback. It also clear all modal state by navigating to root.
 * @returns
 */
export const useCloseConnect = () => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useVirtualNavigation()
  const onCloseConnect = useCallback(async () => {
    await dispatch(setVisibleConnect(false))
    return navigate('/')
  }, [dispatch, navigate])
  return onCloseConnect
}
