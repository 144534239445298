import { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Button, Col, Input, message, Row, Typography } from 'antd'

import { useUsername } from 'hooks/useUser'
import { AppDispatch } from 'store'
import { updateProfile } from 'store/user.reducer'
import { validateUsername } from 'libs/api/passwordAuth'

const Username = () => {
  const defaultUsername = useUsername()
  const [username, setUsername] = useState(defaultUsername || '')
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch<AppDispatch>()

  const onSave = useCallback(async () => {
    try {
      setLoading(true)
      const existed = await validateUsername(username)
      if (existed) throw new Error(`Username ${username} already exists.`)
      await dispatch(updateProfile({ username })).unwrap()
      message.success('You have successfully update your username.')
      return setUsername('')
    } catch (er: any) {
      return message.error(er.message)
    } finally {
      return setLoading(false)
    }
  }, [dispatch, username])

  const disabled = useMemo(
    () => !username || username === defaultUsername,
    [username, defaultUsername],
  )

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Row gutter={[12, 12]} wrap={false}>
          <Col flex="auto">
            <Typography.Title level={5}>Username</Typography.Title>
          </Col>
          <Col>
            {!disabled && (
              <Button type="link" size="small" onClick={() => setUsername('')}>
                Cancel
              </Button>
            )}
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Input
          placeholder="Enter Username"
          size="large"
          value={username || defaultUsername}
          onChange={(e) => setUsername(e.target.value)}
          suffix={
            <Button
              type="link"
              size="small"
              style={{ marginRight: -7 }}
              loading={loading}
              onClick={onSave}
              disabled={disabled}
            >
              Save
            </Button>
          }
          readOnly={loading}
        />
      </Col>
    </Row>
  )
}

export default Username
