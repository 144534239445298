import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AuthType } from 'libs/api/authType'
import { AppDispatch, AppState } from 'store'
import { getProfile } from 'store/user.reducer'
import {
  AccessToken,
  clearAccessToken,
  setAccessToken,
} from 'libs/api/accessToken'
import { logout as logoutApi } from 'libs/api/profile'

export const useFetchProfile = () => {
  const dispatch = useDispatch<AppDispatch>()
  const fetchProfile = useCallback(() => {
    return dispatch(getProfile())
  }, [dispatch])
  return fetchProfile
}

export const useLogin = () => {
  const dispatch = useDispatch<AppDispatch>()
  const login = useCallback(
    (payload: AccessToken) => {
      setAccessToken(payload)
      return dispatch(getProfile())
    },
    [dispatch],
  )
  return login
}

export const useLogout = () => {
  const logout = useCallback(async () => {
    await logoutApi()
    clearAccessToken()
    return window.location.reload()
  }, [])
  return logout
}

export const useUserId = () => {
  const id = useSelector(({ user }: AppState) => user.profile._id)
  return id
}

export const useUserAvatar = () => {
  const avatar = useSelector(({ user }: AppState) => user.profile.avatar)
  return avatar
}

export const useUsername = () => {
  const username = useSelector(({ user }: AppState) => user.profile.username)
  return username
}

export const useUserEmail = () => {
  const email = useSelector(({ user }: AppState) => user.profile.email)
  return email
}

export const useAuthEntitiesByType = (type: AuthType) => {
  const authEntities = useSelector(({ user }: AppState) => user.authEntities)
  const authEntity = useMemo(() => {
    return authEntities.filter((authEntity) => authEntity.type === type)
  }, [authEntities, type])
  return authEntity
}

export const useAuthEntityById = (_id: string) => {
  const authEntities = useSelector(({ user }: AppState) => user.authEntities)
  const authEntity = useMemo(() => {
    return authEntities.find((authEntity) => authEntity._id === _id)
  }, [authEntities, _id])
  return authEntity
}
