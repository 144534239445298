import { Button, Col, Input, Row, Space, Typography } from 'antd'
import IonIcon from '@sentre/antd-ionicon'
import EmailAuthTitle from './title'

import { useVirtualNavigation } from 'hooks/useVirtualRoute'
import { isEmailAddress } from 'libs/util'

export type EmailAuthLoginProps = {
  email: string
  onEmail: (email: string) => void
  onContinue: () => void
  loading?: boolean
}

const EmailAuthLogin = ({
  email = '',
  onEmail = () => {},
  onContinue = () => {},
  loading = false,
}: EmailAuthLoginProps) => {
  const navigate = useVirtualNavigation()

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <EmailAuthTitle title="LOG IN WITH EMAIL" />
      </Col>
      <Col span={24} />
      <Col span={24}>
        <Input
          placeholder="Enter your email address"
          size="large"
          status={!email || isEmailAddress(email) ? undefined : 'error'}
          value={email}
          onChange={(e) => onEmail(e.target.value)}
          onPressEnter={() => {
            if (isEmailAddress(email)) onContinue()
          }}
        />
      </Col>
      <Col span={24}>
        <Button
          type="primary"
          size="large"
          onClick={onContinue}
          disabled={!isEmailAddress(email)}
          loading={loading}
          block
        >
          Continue
        </Button>
      </Col>
      <Col span={24} style={{ height: 24 }} />
      <Col span={24}>
        <Row gutter={[12, 12]} justify="center">
          <Col>
            <Space direction="vertical" align="center" size={0}>
              <Typography.Text>
                Want to use other login methods?
              </Typography.Text>
              <Button
                type="link"
                icon={<IonIcon name="arrow-back-outline" />}
                onClick={() => navigate('/')}
              >
                Back to Login
              </Button>
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default EmailAuthLogin
