import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { parse } from 'uri-js'

export type VirtualRouteState = {
  index: number
  path: string
  query: string
  history: string[]
}

/**
 * Normalized parse
 * @param route
 */
export const parseVirtualRoute = (route: string) => {
  while (route.endsWith('/')) route = route.substring(0, route.length - 1)
  while (route.includes('//')) route = route.replace('//', '/')
  let { path, query } = parse(route)
  path = path || ''
  query = query ? `?${query}` : ''
  return { path, query }
}

/**
 * Store constructor
 */

const NAME = 'virtualRoute'
const initialState: VirtualRouteState = {
  index: 0,
  path: '',
  query: '',
  history: [''],
}

/**
 * Actions
 */

export const push = createAsyncThunk<
  Partial<VirtualRouteState>,
  string,
  { state: any }
>(`${NAME}/push`, async (route, { getState }) => {
  const {
    virtualRoute: { index: prevIndex, history: prevHistory },
  } = getState()
  const index = prevIndex + 1
  const history = [...prevHistory.slice(0, index), route]
  const { path, query } = parseVirtualRoute(route)
  return { index, path, query, history }
})

export const replace = createAsyncThunk<
  Partial<VirtualRouteState>,
  string,
  { state: any }
>(`${NAME}/replace`, async (route, { getState }) => {
  const {
    virtualRoute: { index: prevIndex, history: prevHistory },
  } = getState()
  const index = prevIndex
  const history = [...prevHistory.slice(0, prevIndex), route]
  const { path, query } = parseVirtualRoute(route)
  return { index, path, query, history }
})

export const back = createAsyncThunk<
  Partial<VirtualRouteState>,
  void,
  { state: any }
>(`${NAME}/back`, async (_, { getState }) => {
  const {
    virtualRoute: { index: prevIndex, history: prevHistory },
  } = getState()
  const index = Math.max(prevIndex - 1, 0)
  const route = prevHistory[index]
  const { path, query } = parseVirtualRoute(route)
  return { index, path, query }
})

export const forward = createAsyncThunk<
  Partial<VirtualRouteState>,
  void,
  { state: any }
>(`${NAME}/forward`, async (_, { getState }) => {
  const {
    virtualRoute: { index: prevIndex, history: prevHistory },
  } = getState()
  const index = Math.min(prevIndex + 1, prevHistory.length - 1)
  const route = prevHistory[index]
  const { path, query } = parseVirtualRoute(route)
  return { index, path, query }
})

/**
 * Usual procedure
 */

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    void builder
      .addCase(
        push.fulfilled,
        (state, { payload }) => void Object.assign(state, payload),
      )
      .addCase(
        replace.fulfilled,
        (state, { payload }) => void Object.assign(state, payload),
      )
      .addCase(
        back.fulfilled,
        (state, { payload }) => void Object.assign(state, payload),
      )
      .addCase(
        forward.fulfilled,
        (state, { payload }) => void Object.assign(state, payload),
      ),
})

export default slice.reducer
