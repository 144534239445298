import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { Col, message, Row, Typography } from 'antd'
import { WalletButton } from 'components/baseButton'
import { EvmLogo } from 'components/logos'
import Navigation from 'view/connect/navigaton'

import { isEthereumAddress, shortenString } from 'libs/util'
import { challengeWallet, validateWallet } from 'libs/api/walletAuth'
import { AuthType } from 'libs/api/authType'
import { useEvmWalletProviders } from 'hooks/useWalletProviders'
import { AppDispatch } from 'store'
import { connectWallet } from 'store/user.reducer'

export type EvmWalletProps = {
  onClose?: () => void
}

const EvmWallet = ({ onClose = () => {} }: EvmWalletProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const providers = useEvmWalletProviders()

  const onConnect = useCallback(
    async (type: keyof typeof providers) => {
      try {
        const provider = providers[type]
        if (!provider.isInstalled) return window.open(provider.url, '_blank')
        await provider.connect()
        const walletAddress = await provider.getAddress()
        if (!isEthereumAddress(walletAddress))
          throw new Error('Invalid address')
        const existed = await validateWallet(walletAddress)
        if (existed)
          throw new Error(
            `The ${shortenString(
              walletAddress,
            )} wallet you selected is already connected to another UID.`,
          )
        const { _id, message } = await challengeWallet(walletAddress)
        const signature = await provider.signMessage(message)
        const payload = {
          type: AuthType.EVMChain,
          credential: {
            authChallengeId: _id,
            walletAddress: walletAddress,
            signedData: signature,
          },
        }
        await dispatch(connectWallet(payload))
        return onClose()
      } catch (er: any) {
        return message.error(er.message)
      }
    },
    [onClose, providers, dispatch],
  )

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Navigation title="EVM" />
      </Col>
      <Col span={24}>
        <Row gutter={[12, 12]} justify="center">
          <Col>
            <EvmLogo width={256} />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Typography.Title
          className="primary"
          level={4}
          style={{ textAlign: 'center' }}
        >
          Select wallet
        </Typography.Title>
      </Col>
      <Col span={24} />
      <Col span={24}>
        <Row gutter={[12, 12]}>
          {(Object.keys(providers) as Array<keyof typeof providers>).map(
            (key) => {
              const { url, name, icon, color, isInstalled } = providers[key]
              return (
                <Col span={24} key={key}>
                  <WalletButton
                    title={name}
                    subtitle={url + (!isInstalled ? ' (not installed)' : '')}
                    icon={icon}
                    color={color}
                    onClick={() => onConnect(key)}
                  />
                </Col>
              )
            },
          )}
        </Row>
      </Col>
    </Row>
  )
}

export default EvmWallet
