import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Col, Empty, Row, Space, Spin, Typography } from 'antd'
import AddClientButton from './addClientButton'

import { AppDispatch } from 'store'
import { getClients } from 'store/client.reducer'
import { useClients } from 'hooks/useClient'
import ClientInfoCard from './clientInfoCard'

const OAuth = () => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch<AppDispatch>()
  const clients = useClients()

  const fetchClients = useCallback(async () => {
    setLoading(true)
    await dispatch(getClients())
    setLoading(false)
  }, [dispatch])

  useEffect(() => {
    fetchClients()
  }, [fetchClients])

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3}>OAuth</Typography.Title>
        <Typography.Paragraph type="secondary">
          Use Ancient8 OAuth as an authorization system or use our API on behalf
          of your users. Add a redirect URI, pick your scopes, and go!
        </Typography.Paragraph>
      </Col>
      <Col span={24}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Space>
              <Typography.Title level={3}>Client Information</Typography.Title>
              <Spin spinning={loading} size="small" />
            </Space>
          </Col>
          {!Object.keys(clients).length && (
            <Col span={24}>
              <Row gutter={[12, 12]} justify="center">
                <Col>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </Col>
              </Row>
            </Col>
          )}
          {Object.keys(clients).map((clientId) => (
            <Col xs={24} xl={12} key={clientId}>
              <ClientInfoCard clientId={clientId} />
            </Col>
          ))}
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[12, 12]} justify="center">
          <Col>
            <AddClientButton />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default OAuth
