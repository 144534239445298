import { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Col, Layout, Row } from 'antd'
import Protected from 'components/privateRoute'
import Header from './header'
import Footer from './footer'
import Welcome from './welcome'
import Watcher from './watcher'
import Connect from './connect'
import Profile from './profile'
import Discord from './discord'
import Twitter from './twitter'
import OAuth from './oauth'

import { useCloseConnect, useVisibleConnect } from 'hooks/useUi'
import { useFetchProfile } from 'hooks/useUser'
import './index.less'

function View() {
  const fetchProfile = useFetchProfile()
  const visibleConnect = useVisibleConnect()
  const onCloseConnect = useCloseConnect()

  useEffect(() => {
    fetchProfile()
  }, [fetchProfile])

  return (
    <Layout className="container">
      <Row gutter={[24, 48]}>
        <Col span={24}>
          <Header />
        </Col>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="center">
            <Col xs={24} md={18}>
              <Routes>
                <Route path="/" element={<Navigate to="/profile" />} />
                <Route path="/welcome" element={<Welcome />} />
                <Route
                  path="/profile"
                  element={
                    <Protected>
                      <Profile />
                    </Protected>
                  }
                />
                <Route path="/discord" element={<Discord />} />
                <Route path="/twitter" element={<Twitter />} />
                <Route
                  path="/oauth"
                  element={
                    <Protected>
                      <OAuth />
                    </Protected>
                  }
                />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Footer />
        </Col>
      </Row>
      <Watcher />
      <Connect open={visibleConnect} onCancel={onCloseConnect} />
    </Layout>
  )
}

export default View
