import IonIcon from '@sentre/antd-ionicon'
import { Avatar, Space, Typography } from 'antd'

import './index.less'

export type WalletButtonProps = {
  title?: string
  subtitle?: string
  icon?: string
  color?: string
  onClick?: () => void
}

export const WalletButton = ({
  title = '',
  subtitle = '',
  icon = '',
  color = '#2eb835',
  onClick = () => {},
}: WalletButtonProps) => {
  return (
    <button
      className="base-btn wallet-btn"
      style={{ background: color }}
      onClick={onClick}
    >
      <Space align="center" size={16}>
        <Avatar size={38} shape="square" src={icon} />
        <Space direction="vertical" size={0} align="start">
          <Typography.Title level={5} style={{ lineHeight: 1 }}>
            {title}
          </Typography.Title>
          <Typography.Text
            type="secondary"
            style={{ maxWidth: 200, fontSize: 10 }}
            ellipsis
          >
            {subtitle}
          </Typography.Text>
        </Space>
      </Space>
      <img className="bg-img" alt={icon} src={icon} />
    </button>
  )
}

export type ChainButtonProps = {
  title?: string
  color?: string
  onClick?: () => void
}

export const ChainButton = ({
  title = '',
  color = '#2eb835',
  onClick = () => {},
}: ChainButtonProps) => {
  return (
    <button
      className="base-btn chain-btn"
      onClick={onClick}
      style={{ background: color }}
    >
      <Typography.Title level={5}>{title}</Typography.Title>
    </button>
  )
}

export type SocialButtonProps = {
  title?: string
  iconName?: string
  onClick?: () => void
}

export const SocialButton = ({
  title = '',
  iconName = 'help-circle',
  onClick,
}: SocialButtonProps) => {
  return (
    <button className="base-btn social-btn" onClick={onClick}>
      <Space>
        <IonIcon name={iconName} />
        <Typography.Title level={5}>{title}</Typography.Title>
      </Space>
    </button>
  )
}
