import { Col, Input, Row, Typography } from 'antd'
import Clipboard from 'components/clipboard'

import { useUserId } from 'hooks/useUser'

const UserId = () => {
  const userId = useUserId()

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Typography.Title level={5}>UID</Typography.Title>
      </Col>
      <Col span={24}>
        <Input
          placeholder="Enter Username"
          size="large"
          value={userId}
          suffix={<Clipboard content={userId || ''} />}
          readOnly
        />
      </Col>
    </Row>
  )
}

export default UserId
