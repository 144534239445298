import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Col, message, Row, Space, Spin, Typography } from 'antd'

import { useQueryParam } from 'hooks/useQueryParam'
import { DISCORD_OAUTH_ORIGIN, loginDiscord } from 'libs/api/discordAuth'
import { AppDispatch } from 'store'
import { connectDiscord } from 'store/user.reducer'
import { useLogin, useUserId } from 'hooks/useUser'
import configs from 'configs'
import storage from 'libs/storage'

const {
  oauth: { discord },
} = configs

const Discord = () => {
  const dispatch = useDispatch<AppDispatch>()
  const code = useQueryParam('code')
  const state = useQueryParam('state')
  const navigate = useNavigate()
  const login = useLogin()
  const userId = useUserId()

  const onCode = useCallback(async () => {
    try {
      if (!code) throw new Error('Invalid states')
      const origin = storage.clear(DISCORD_OAUTH_ORIGIN) || '/profile'
      if (state === 'login' && !userId) {
        const data = await loginDiscord({
          callbackUri: discord.callbackUri,
          exchangeCode: code,
        })
        await login(data)
        return navigate(origin, { replace: true })
      }
      if (state === 'connect' && userId) {
        await dispatch(
          connectDiscord({
            callbackUri: discord.callbackUri,
            exchangeCode: code,
          }),
        ).unwrap()
        return navigate(origin, { replace: true })
      }
    } catch (er: any) {
      message.error(
        er.message === 'Request failed with status code 409' ||
          er.message === 'Request failed with status code 422'
          ? 'Your Discord account had been connected to another UID.'
          : er.message,
      )
      return navigate('/profile', { replace: true })
    }
  }, [dispatch, code, state, navigate, login, userId])

  useEffect(() => {
    onCode()
  }, [onCode])

  return (
    <Row gutter={[24, 24]} justify="center">
      <Col style={{ marginTop: 96, marginBottom: 96 }}>
        <Space direction="vertical" align="center">
          <Spin />
          <Typography.Text>Authorizing the application...</Typography.Text>
        </Space>
      </Col>
    </Row>
  )
}

export default Discord
