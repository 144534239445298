import { Navigate } from 'react-router-dom'

import { useUserId } from 'hooks/useUser'

export type ProtectedProps = { children: JSX.Element }

const Protected = ({ children }: ProtectedProps) => {
  const userId = useUserId()
  const pathname = window.location.href.replace(window.location.origin, '')
  const openWallet = pathname.startsWith('/oauth')
  const redirect = encodeURIComponent(
    window.location.href.replace(window.location.origin, ''),
  )
  if (userId) return children
  return (
    <Navigate to={`/welcome?redirect=${redirect}&openWallet=${openWallet}`} />
  )
}

export default Protected
