import { Col, Divider, Row, Space, Typography } from 'antd'
import { Ancient8Logo } from 'components/logos'
import { IconRouterLink } from 'components/routerLink'

const SOCIALS = [
  { iconName: 'logo-twitter', url: 'https://twitter.com/Ancient8_gg/' },
  { iconName: 'logo-discord', url: 'https://discord.com/invite/ancient8/' },
  { iconName: 'logo-telegram', url: 'https://t.me/ancient8_gg/' },
  {
    iconName: 'logo-youtube',
    url: 'https://www.youtube.com/channel/UCv4p3wP6A6Li2UYhGsh3tDQ/',
  },
  { iconName: 'mail', url: 'mailto:Contact@ancient8.gg/' },
  { iconName: 'document-text', url: 'https://blog.ancient8.gg/' },
  { iconName: 'book', url: 'https://whitepaper.ancient8.gg/ancient8/' },
]

const Footer = () => {
  return (
    <Row gutter={[24, 48]}>
      <Col span={24}>
        <Row gutter={[12, 12]} justify="center">
          <Col span={20}>
            <Divider />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[12, 12]} justify="center">
          <Col>
            <Space direction="vertical" align="center">
              <Typography.Text>Join Our Community</Typography.Text>
              <Space wrap={false}>
                {SOCIALS.map(({ iconName, url }, i) => (
                  <IconRouterLink key={i} iconName={iconName} url={url} />
                ))}
              </Space>
            </Space>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[12, 12]} justify="center">
          <Col>
            <Space direction="vertical" align="center">
              <Ancient8Logo width={192} />
              <Typography.Text>
                Copyright © 2022 Ancient8. All Rights Reserved.
              </Typography.Text>
            </Space>
          </Col>
        </Row>
      </Col>
      <Col span={24} />
    </Row>
  )
}

export default Footer
