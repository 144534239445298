import axios from 'axios'

import configs from 'configs'
import { AccessToken, getAuthorizationHeader } from './accessToken'

const { api } = configs

export const DISCORD_OAUTH_ORIGIN = 'discord_oauth_origin'

export type DiscordAuthCredential = {
  exchangeCode: string
  callbackUri: string
}

/**
 * Sign up user using credential from discord authorization code.
 * @param payload
 */
export const loginDiscord = async (
  payload: DiscordAuthCredential,
): Promise<AccessToken> => {
  const { data } = await axios.post(`${api.auth}/login-discord`, payload)
  return data
}

/**
 * Connect discord to an existing uid.
 * @param payload
 */
export const connectDiscord = async (
  payload: DiscordAuthCredential,
): Promise<AccessToken> => {
  const { data } = await axios.post(`${api.auth}/connect-discord`, payload, {
    headers: getAuthorizationHeader(),
  })
  return data
}
