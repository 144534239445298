import { useMemo } from 'react'

import PhantomSolanaAdapter from 'libs/adapters/phantom.sol'
import Coin98SolanaAdapter from 'libs/adapters/coin98.sol'
import MetamaskEvmAdapter from 'libs/adapters/metamask.evm'
import Coin98EvmAdapter from 'libs/adapters/coin98.evm'
import BinanceEvmAdapter from 'libs/adapters/binance.evm'
import CoinbaseEvmAdapter from 'libs/adapters/coinbase.evm'

export const useSolanaWalletProviders = () => {
  const providers = useMemo(
    () => ({
      phantom: new PhantomSolanaAdapter(),
      coin98: new Coin98SolanaAdapter(),
    }),
    [],
  )
  return providers
}

export const useEvmWalletProviders = () => {
  const providers = useMemo(
    () => ({
      metamask: new MetamaskEvmAdapter(),
      coin98: new Coin98EvmAdapter(),
      binance: new BinanceEvmAdapter(),
      coinbase: new CoinbaseEvmAdapter(),
    }),
    [],
  )
  return providers
}
