import axios from 'axios'

import configs from 'configs'
import { AccessToken, getAuthorizationHeader } from './accessToken'
import { AuthType } from './authType'

const { api } = configs

export type EmailAuth = {
  type: AuthType
  token: string
  email: string
}

/**
 * Validate user email has existed.
 * @param email
 * True if existing, otherwise False
 */
export const validateEmail = async (email: string) => {
  try {
    await axios.post(
      `${api.user}/validate/username/${encodeURIComponent(email)}`,
      {},
    )
    return false
  } catch (er) {
    return true
  }
}

/**
 * Request challenge for email authentication. The challenge an OTP token for signup.
 * @param email
 */
export const challengeEmailOtpForSignup = async (
  email: string,
): Promise<AccessToken> => {
  const { data } = await axios.post(
    `${api.auth}/send-email-verification/${encodeURIComponent(email)}`,
    {},
  )
  return data
}

/**
 * Signup using email otp token.
 * @param payload
 */
export const signupEmailOtp = async (
  payload: EmailAuth,
): Promise<AccessToken> => {
  const { data } = await axios.post(`${api.auth}/sign-up`, payload)
  return data
}

/**
 * Request challenge for email authentication. The challenge an OTP token for login.
 * @param email
 */
export const challengeEmailOtpForLogin = async (
  email: string,
): Promise<AccessToken> => {
  const { data } = await axios.post(
    `${api.auth}/send-email-otp/${encodeURIComponent(email)}`,
    {},
  )
  return data
}

/**
 * Login using email otp token.
 * @param payload
 */
export const loginEmailOtp = async (
  payload: Omit<EmailAuth, 'type'>,
): Promise<AccessToken> => {
  const { data } = await axios.post(`${api.auth}/login-email-otp`, payload)
  return data
}

/**
 * Connect email to an existing id.
 * @param payload
 * @returns
 */
export const connectEmail = async (payload: Omit<EmailAuth, 'type'>) => {
  const { data } = await axios.post(`${api.auth}/connect-email`, payload, {
    headers: getAuthorizationHeader(),
  })
  return data
}
