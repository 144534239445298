import { PublicKey } from '@solana/web3.js'
import web3 from 'web3'
import { randomBytes } from 'tweetnacl'
import { encode } from 'bs58'

/**
 * Delay by async/await
 * @param ms - milisenconds
 * @returns
 */
export const asyncWait = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/**
 * Shorten a long string
 * @param str - The long string
 * @param num - The number of the heading and trailing characters
 * @param delimiter - The delimiter
 * @returns Shortened string
 */
export const shortenString = (str: string, num = 4, delimiter = '...') => {
  if (str.length <= 2 * num + 3) return str
  return (
    str.substring(0, num) +
    delimiter +
    str.substring(str.length - num, str.length)
  )
}

/**
 * Validate Solana address
 * @param address Solana address
 * @returns true/false
 */
export const isSolanaAddress = (
  address: string | undefined,
): address is string => {
  if (!address) return false
  try {
    const publicKey = new PublicKey(address)
    if (!publicKey) throw new Error('Invalid public key')
    return true
  } catch (er) {
    return false
  }
}

/**
 * Validate Solana address
 * @param address Solana address
 * @returns true/false
 */
export const isEthereumAddress = (
  address: string | undefined,
): address is string => {
  if (!address) return false
  return web3.utils.isAddress(address)
}

/**
 * Validate Email address
 * @param email Email address
 * @returns true/false
 */
export const isEmailAddress = (email: string | undefined): email is string => {
  if (!email) return false
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return pattern.test(email)
}

/**
 * Randomize a base58-string nonce
 */
export const randNonce = () => {
  const buf = randomBytes(32)
  return encode(buf)
}
