import { Card, Col, Divider, Row, Typography } from 'antd'
import UserAvatar from './userAvatar'
import UserId from './userId'
import Username from './username'
import Email from './email'
import KYC from './kyc'
import WalletList from './walletList'
import Discord from './discord'
import Twitter from './twitter'

const General = () => {
  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3}>User Profile</Typography.Title>
        <Typography.Paragraph type="secondary">
          What should we call you? How do you want your profile to look like?
          Customize it here!
        </Typography.Paragraph>
      </Col>
      <Col span={24}>
        <Card className="glass" bordered={false}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Row gutter={[12, 12]} justify="center">
                <Col>
                  <UserAvatar />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]} justify="center">
                <Col xs={24} md={12}>
                  <UserId />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]} justify="center">
                <Col xs={24} md={12}>
                  <Username />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Divider />
            </Col>
            <Col xs={24} md={12}>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Email />
                </Col>
                <Col span={24}>
                  <Discord />
                </Col>
                <Col span={24}>
                  <Twitter />
                </Col>
                <Col span={24}>
                  <WalletList />
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={12}>
              <KYC />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  )
}

export default General
