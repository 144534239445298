import { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Button, Col, Input, message, Row, Space, Typography } from 'antd'
import Clipboard from 'components/clipboard'
import Primary from '../primary'

import configs from 'configs'
import { useAuthEntitiesByType } from 'hooks/useUser'
import { AuthType } from 'libs/api/authType'
import { AppDispatch } from 'store'
import { deleteAuthEntity } from 'store/user.reducer'

const {
  oauth: {
    discord: { authorizationEndpoint, clientId, callbackUri },
  },
} = configs

const Discord = () => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch<AppDispatch>()
  const authEntities = useAuthEntitiesByType(AuthType.Discord) || []

  const { isPrimary, credential, _id, createdAt } =
    authEntities.length > 0 ? authEntities[0] : ({} as any)
  const discordUserId = useMemo(
    () =>
      credential && 'discordUserId' in credential
        ? credential.discordUserId
        : '',
    [credential],
  )
  const disabled =
    isPrimary || Date.now() - Number(new Date(createdAt)) < 48 * 60 * 60 * 1000

  const onConnect = useCallback(() => {
    window.location.href = `${authorizationEndpoint}?response_type=code&client_id=${clientId}&scope=identify&state=connect&redirect_uri=${encodeURIComponent(
      callbackUri,
    )}&prompt=consent`
  }, [])

  const onRemove = useCallback(async () => {
    try {
      if (!_id) return
      setLoading(true)
      await dispatch(deleteAuthEntity(_id)).unwrap()
      return message.success('You have removed the method!')
    } catch (er: any) {
      return message.error(er.message)
    } finally {
      return setLoading(false)
    }
  }, [dispatch, _id])

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Row gutter={[12, 12]} wrap={false} align="middle">
          <Col flex="auto">
            <Space>
              <Typography.Title level={5}>Discord</Typography.Title>
              <Primary type={AuthType.Discord} />
            </Space>
          </Col>
          <Col>
            {discordUserId && (
              <Button
                type="link"
                size="small"
                onClick={onRemove}
                loading={loading}
                disabled={disabled}
              >
                Remove
              </Button>
            )}
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Input
          placeholder="Connect Discord"
          size="large"
          value={discordUserId}
          suffix={
            discordUserId ? (
              <Clipboard content={discordUserId} style={{ marginRight: -7 }} />
            ) : (
              <Button
                type="link"
                style={{ marginRight: -7 }}
                loading={loading}
                onClick={onConnect}
              >
                Connect
              </Button>
            )
          }
        />
      </Col>
    </Row>
  )
}

export default Discord
