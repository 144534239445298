import { CSSProperties, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { Button, Col, Row } from 'antd'
import IonIcon from '@sentre/antd-ionicon'

import { AppDispatch } from 'store'
import { back, forward } from 'store/virtualRoute.reducer'
import { useBackable } from 'hooks/useVirtualRoute'
import { useForwardable } from 'hooks/useVirtualRoute'

export type BackProps = { style?: CSSProperties }

export const Back = ({ style }: BackProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const onBack = useCallback(() => dispatch(back()), [dispatch])
  const backable = useBackable()

  return (
    <Button
      type="text"
      size="large"
      icon={<IonIcon name="arrow-back" />}
      onClick={onBack}
      disabled={!backable}
      style={{ color: '#2eb835', ...style }}
    />
  )
}

export type ForwardProps = { style?: CSSProperties }

export const Forward = ({ style }: ForwardProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const onForward = useCallback(() => dispatch(forward()), [dispatch])
  const forwardable = useForwardable()

  return (
    <Button
      type="text"
      size="large"
      icon={<IonIcon name="arrow-forward" />}
      onClick={onForward}
      disabled={!forwardable}
      style={{ color: '#2eb835', ...style }}
    />
  )
}

export type NavigationProps = { title?: string }

const Navigation = ({ title = '' }: NavigationProps) => {
  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Row gutter={[12, 12]} justify="space-between" align="middle">
          <Col span={8}>
            <Back style={{ marginLeft: -12 }} />
          </Col>
          <Col>{title}</Col>
          <Col span={8} />
        </Row>
      </Col>
    </Row>
  )
}

export default Navigation
