import { useMemo } from 'react'

import { Avatar, Col, Row, Typography } from 'antd'
import { SocialButton } from 'components/baseButton'
import { ChainButton } from 'components/baseButton'

import configs from 'configs'
import { useVirtualNavigation } from 'hooks/useVirtualRoute'
import { requestToken, TWITTER_OAUTH_ORIGIN } from 'libs/api/twitterAuth'

import WELCOME_LOGO from 'static/images/a8-welcome.png'
import { useQueryParam } from 'hooks/useQueryParam'
import storage from 'libs/storage'
import { DISCORD_OAUTH_ORIGIN } from 'libs/api/discordAuth'

const {
  oauth: { discord, twitter },
} = configs

export const CHAINS = {
  solana: {
    color: 'linear-gradient(225deg, #00f7ac, #d943ef)',
    path: '/solana',
    title: 'Continue with Solana',
  },
  evm: {
    color: '#637eea',
    path: '/evm',
    title: 'Continue with EVM',
  },
}

const ConnectHome = () => {
  const navigate = useVirtualNavigation()
  const redirectOrigin = useQueryParam('redirect')

  const socials = useMemo(() => {
    return {
      email: {
        title: 'Continue with Email',
        iconName: 'mail',
        onClick: () => navigate('/email'),
      },
      discord: {
        title: 'Continue with Discord',
        iconName: 'logo-discord',
        onClick: () => {
          storage.set(DISCORD_OAUTH_ORIGIN, redirectOrigin)
          window.location.href = `${
            discord.authorizationEndpoint
          }?response_type=code&client_id=${
            discord.clientId
          }&scope=identify&state=login&redirect_uri=${encodeURIComponent(
            discord.callbackUri,
          )}&prompt=consent`
        },
      },
      twitter: {
        title: 'Continue with Twitter',
        iconName: 'logo-twitter',
        onClick: async () => {
          storage.set(TWITTER_OAUTH_ORIGIN, redirectOrigin)
          const { token } = await requestToken()
          window.location.href = `${twitter.authorizationEndpoint}?oauth_token=${token}`
        },
      },
    }
  }, [navigate, redirectOrigin])

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Row gutter={[12, 12]} justify="center">
          <Col>
            <Avatar src={WELCOME_LOGO} style={{ width: 168, height: 147 }} />
          </Col>
          <Col span={24}>
            <Typography.Title
              className="primary"
              level={4}
              style={{ textAlign: 'center' }}
            >
              WELCOME TO ANCIENT8 USER IDENTITY
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Typography.Paragraph style={{ textAlign: 'center', margin: 0 }}>
              Please select a desired chain below
            </Typography.Paragraph>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[12, 12]}>
          {(Object.keys(CHAINS) as Array<keyof typeof CHAINS>).map((key) => {
            const { color, path, title } = CHAINS[key]
            return (
              <Col key={key} span={24}>
                <ChainButton
                  color={color}
                  onClick={() => navigate(path)}
                  title={title}
                />
              </Col>
            )
          })}
        </Row>
      </Col>
      <Col span={24}>
        <Typography.Paragraph style={{ textAlign: 'center', margin: 0 }}>
          OR
        </Typography.Paragraph>
      </Col>
      <Col span={24}>
        <Row gutter={[12, 12]}>
          {(Object.keys(socials) as Array<keyof typeof socials>).map((key) => {
            const { title, iconName, onClick } = socials[key]
            return (
              <Col key={key} span={24}>
                <SocialButton
                  title={title}
                  iconName={iconName}
                  onClick={onClick}
                />
              </Col>
            )
          })}
        </Row>
      </Col>
      <Col span={24}>
        <Typography.Paragraph
          type="secondary"
          style={{ textAlign: 'center', margin: 2 }}
        >
          By connecting, you agree to our
        </Typography.Paragraph>
        <Typography.Paragraph
          className="primary"
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => window.open('https://ancient8.gg', '_blank')}
          underline
        >
          Privacy Policy and Terms of Services
        </Typography.Paragraph>
      </Col>
    </Row>
  )
}

export default ConnectHome
