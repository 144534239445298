import { Env } from './env'

/**
 * Contructor
 */

type Conf = {
  discord: {
    authorizationEndpoint: string
    clientId: string
    callbackUri: string
  }
  twitter: {
    authorizationEndpoint: string
    callbackUri: string
  }
}

const conf: Record<Env, Conf> = {
  /**
   * Development configurations
   */
  development: {
    discord: {
      authorizationEndpoint: 'https://discord.com/oauth2/authorize',
      clientId: '1131111014990094426',
      callbackUri: 'http://localhost:3000/discord',
    },
    twitter: {
      authorizationEndpoint: 'https://api.twitter.com/oauth/authenticate',
      callbackUri: 'http://localhost:3000/twitter',
    },
  },

  /**
   * Development configurations
   */
  staging: {
    discord: {
      authorizationEndpoint: 'https://discord.com/oauth2/authorize',
      clientId: '1131111014990094426',
      callbackUri: 'https://auth.ancient8.dev/discord',
    },
    twitter: {
      authorizationEndpoint: 'https://api.twitter.com/oauth/authenticate',
      callbackUri: 'https://auth.ancient8.dev/twitter',
    },
  },

  /**
   * Production configurations
   */
  production: {
    discord: {
      authorizationEndpoint: 'https://discord.com/oauth2/authorize',
      clientId: '1131111014990094426',
      callbackUri: 'https://auth.ancient8.gg/discord',
    },
    twitter: {
      authorizationEndpoint: 'https://api.twitter.com/oauth/authenticate',
      callbackUri: 'https://auth.ancient8.gg/twitter',
    },
  },
}

/**
 * Module exports
 */
export default conf
