import { env } from './env'
import api from './api.config'
import oauth from './oauth.config'

const configs = {
  api: api[env],
  oauth: oauth[env],
}

/**
 * Module exports
 */
export default configs
