export enum AuthType {
  EVMChain = 'AUTH_TYPE::EVM_CHAIN',
  Solana = 'AUTH_TYPE::SOLANA',
  Password = 'AUTH_TYPE::PASSWORD',
  Discord = 'AUTH_TYPE::DISCORD',
  Twitter = 'AUTH_TYPE::TWITTER',
  EmailOTP = 'AUTH_TYPE::EMAIL_OTP',
}

export type WalletCredential = {
  walletAddress: string
}

export type DiscordAuthCredential = {
  discordUserId: string
}

export type TwitterAuthCredential = {
  twitterUserId: string
}

export interface AuthEntity {
  _id: string
  userId: string
  type: AuthType
  credential?: WalletCredential | DiscordAuthCredential | TwitterAuthCredential
  isPrimary: boolean
}
