import { Button, Dropdown, Menu, Space } from 'antd'
import IonIcon from '@sentre/antd-ionicon'
import { TextRouterLink } from 'components/routerLink'

import { useInfix } from 'hooks/useUi'
import { Infix } from 'store/ui.reducer'

const LINKS = [
  { title: 'Research', url: 'https://ancient8.gg/research/' },
  { title: 'Dojo Launchpad', url: 'https://ancient8.gg/dojo/nftsale/' },
  { title: 'Blog', url: 'https://blog.ancient8.gg/' },
  { title: 'Careers', url: 'https://ancient8.gg/careers/' },
]
const ABOUT = [
  { title: 'White Paper', url: 'https://whitepaper.ancient8.gg/ancient8/' },
  {
    title: 'Media Kit',
    url: 'https://drive.google.com/drive/folders/1QnW9mhEV6RC6397gdwlLiYLTsFVCcIKC/',
  },
  { title: 'Twitter', url: 'https://twitter.com/Ancient8_gg/' },
  { title: 'Discord', url: 'https://discord.com/invite/ancient8/' },
  { title: 'Saint Giong NFT', url: 'https://nft.ancient8.gg/' },
]

const DesktopNavigation = () => {
  return (
    <Space>
      {LINKS.map(({ title, url }, i) => (
        <TextRouterLink key={i} title={title} url={url} />
      ))}
      <Dropdown
        overlay={
          <Menu
            items={ABOUT.map(({ title, url }, i) => ({
              key: i,
              label: <TextRouterLink key={i} title={title} url={url} />,
            }))}
          />
        }
      >
        <Button
          className="router-link"
          type="text"
          icon={<IonIcon name="chevron-down" />}
        >
          About
        </Button>
      </Dropdown>
    </Space>
  )
}

const MobileNavigation = () => {
  return (
    <Dropdown
      overlay={
        <Menu
          items={[
            ...LINKS.map(({ title, url }) => ({
              key: title,
              label: <TextRouterLink key={title} title={title} url={url} />,
            })),
            { type: 'divider' },
            ...ABOUT.map(({ title, url }) => ({
              key: title,
              label: <TextRouterLink key={title} title={title} url={url} />,
            })),
          ]}
        />
      }
    >
      <Button
        className="router-link"
        type="text"
        icon={<IonIcon name="menu" />}
      >
        Menu
      </Button>
    </Dropdown>
  )
}

const Navigation = () => {
  const infix = useInfix()
  return infix < Infix.lg ? <MobileNavigation /> : <DesktopNavigation />
}

export default Navigation
