import { useMemo } from 'react'

import { Card, Col, Row, Space, Typography } from 'antd'
import { EvmLogo } from 'components/logos'
import Clipboard from 'components/clipboard'
import Primary from '../primary'
import RemoveButton from './removeButton'

import { shortenString } from 'libs/util'
import { AuthEntity, AuthType } from 'libs/api/authType'
import { useAuthEntitiesByType } from 'hooks/useUser'

const EvmChainCard = ({
  authEntity: { credential, _id },
}: {
  authEntity: AuthEntity
}) => {
  const evmWalletAddress = useMemo(
    () =>
      credential && 'walletAddress' in credential
        ? credential.walletAddress
        : '',
    [credential],
  )

  if (!_id) return null
  return (
    <Card bodyStyle={{ padding: 16 }}>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Row gutter={[12, 12]} wrap={false} align="middle">
            <Col flex="auto">
              <Space>
                <Typography.Text type="secondary">EVM Chain</Typography.Text>
                <Primary type={AuthType.EVMChain} />
              </Space>
            </Col>
            <Col style={{ marginRight: -10 }}>
              <EvmLogo width={124} />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[12, 12]} wrap={false} align="middle">
            <Col flex="auto">
              <Space>
                <Typography.Title level={5}>
                  {shortenString(evmWalletAddress || '')}
                </Typography.Title>
                <Clipboard content={evmWalletAddress} />
              </Space>
            </Col>
            <Col>
              <RemoveButton authEntityId={_id} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}

const EvmChain = () => {
  const authEntities = useAuthEntitiesByType(AuthType.EVMChain) || []
  return (
    <Row gutter={[8, 8]}>
      {authEntities.map((authEntity) => (
        <Col key={authEntity._id} span={24}>
          <EvmChainCard authEntity={authEntity} />
        </Col>
      ))}
    </Row>
  )
}

export default EvmChain
