import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  ClientData,
  getClients as getClientsApi,
  createClient as createClientApi,
  deleteClient as deleteClientApi,
} from 'libs/api/client'

export type ClientState = Record<string, ClientData>

/**
 * Store constructor
 */

const NAME = 'client'
const initialState: ClientState = {}

/**
 * Actions
 */

export const getClients = createAsyncThunk(`${NAME}/getClients`, async () => {
  const data = await getClientsApi()
  let clients: Record<string, ClientData> = {}
  data.forEach((client: ClientData) => (clients[client._id] = client))
  return clients
})

export const createClient = createAsyncThunk(
  `${NAME}/createClient`,
  async (client: Parameters<typeof createClientApi>[0]) => {
    const data = await createClientApi(client)
    return { [data._id]: data }
  },
)

export const deleteClient = createAsyncThunk(
  `${NAME}/deleteClient`,
  async (clientId: string) => {
    await deleteClientApi(clientId)
    return { clientId }
  },
)

/**
 * Usual procedure
 */

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    void builder
      .addCase(
        getClients.fulfilled,
        (state, { payload }) => void Object.assign(state, payload),
      )
      .addCase(
        createClient.fulfilled,
        (state, { payload }) => void Object.assign(state, payload),
      )
      .addCase(
        deleteClient.fulfilled,
        (state, { payload }) => void delete state[payload.clientId],
      ),
})

export default slice.reducer
