import icon from './assets/coin98.png'
import AdapterInterface, { Chain } from './adapter.interface'

class Coin98EvmAdapter implements AdapterInterface {
  // Private provider
  private provider = window.coin98?.provider
  // Public params
  public name = 'Coin98'
  public chain = Chain.evm
  public icon = icon
  public url = 'https://wallet.coin98.com'
  public color =
    'linear-gradient(90deg, rgb(204 173 65) 0%, rgb(13, 13, 24) 100%)'
  public isInstalled = this.provider?.isCoin98
  // Public functions
  public connect = async () => {
    const [account] =
      (await this.provider?.request({ method: 'eth_requestAccounts' })) || []
    return account
  }
  public install = () => window.open(this.url, '_blank')
  public signMessage = async (msg: string) => {
    const address = await this.getAddress()
    const hex = `0x${Buffer.from(msg, 'utf8').toString('hex')}`
    const sig = await this.provider.request({
      method: 'personal_sign',
      params: [hex, address.toLowerCase()],
    })
    return sig
  }
  public getAddress = async () => {
    const address = await this.connect()
    if (!address) throw new Error('Invalid address')
    return address
  }
  public disconnect = this.provider?.disconnect
}

export default Coin98EvmAdapter
