import { Fragment, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Button, Col, Input, Modal, Row, Typography } from 'antd'
import IonIcon from '@sentre/antd-ionicon'

import { AppDispatch } from 'store'
import { createClient } from 'store/client.reducer'

const AddClientButton = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [visible, setVisible] = useState(false)
  const [clientName, setClientName] = useState('')
  const [hostname, setHostname] = useState('')
  const [redirectUri, setRedirectUri] = useState('')
  const [avatar, setAvatar] = useState('')

  const onCancel = useCallback(() => {
    setVisible(false)
    setClientName('')
    setHostname('')
    setRedirectUri('')
    setAvatar('')
  }, [])

  const onSubmit = useCallback(async () => {
    const client = {
      clientName,
      hostname,
      redirectUri,
      avatar,
      sessionExpiresDelta: '365d',
    }
    await dispatch(createClient(client))
    return onCancel()
  }, [dispatch, clientName, hostname, redirectUri, avatar, onCancel])

  return (
    <Fragment>
      <Button
        type="primary"
        icon={<IonIcon name="add" />}
        onClick={() => setVisible(true)}
      >
        Add Client
      </Button>
      <Modal
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        closeIcon={<IonIcon name="close" />}
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Typography.Title level={4}>Add Client</Typography.Title>
          </Col>
          <Col span={24}>
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <Input
                  placeholder="Client Name"
                  size="large"
                  value={clientName}
                  onChange={(e) => setClientName(e.target.value)}
                />
              </Col>
              <Col span={24}>
                <Input
                  placeholder="Hostname: example.com"
                  size="large"
                  value={hostname}
                  onChange={(e) => setHostname(e.target.value)}
                />
              </Col>
              <Col span={24}>
                <Input
                  placeholder="Redirect URI: https://example.com/oauth"
                  size="large"
                  value={redirectUri}
                  onChange={(e) => setRedirectUri(e.target.value)}
                />
              </Col>
              <Col span={24}>
                <Input
                  placeholder="Avatar"
                  size="large"
                  value={avatar}
                  onChange={(e) => setAvatar(e.target.value)}
                />
              </Col>
              <Col span={12}>
                <Button type="text" size="large" onClick={onCancel} block>
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Button type="primary" size="large" onClick={onSubmit} block>
                  Submit
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </Fragment>
  )
}

export default AddClientButton
