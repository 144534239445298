import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Button, Col, Input, Modal, Row, Typography } from 'antd'
import IonIcon from '@sentre/antd-ionicon'

import { AppDispatch } from 'store'
import { connectEmail } from 'store/user.reducer'

export type EmailVerificationProps = {
  email: string
  onComplete?: () => void
}

const EmailVerification = ({
  email,
  onComplete = () => {},
}: EmailVerificationProps) => {
  const [code, setCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [error, setError] = useState('')
  const dispatch = useDispatch<AppDispatch>()

  const onVerifyOtp = useCallback(async () => {
    try {
      setLoading(true)
      await dispatch(connectEmail({ email, token: code })).unwrap()
      return setError('')
    } catch (er: any) {
      return setError(er.message)
    } finally {
      setLoading(false)
      return setVisible(true)
    }
  }, [dispatch, email, code])

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Input
          placeholder="Enter OTP"
          size="large"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          suffix={
            <Button
              type="link"
              style={{ marginRight: -7 }}
              onClick={onVerifyOtp}
              disabled={!code}
              loading={loading}
            >
              Verify
            </Button>
          }
          autoFocus
        />
      </Col>
      <Modal
        open={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        closeIcon={<IonIcon name="close" />}
        centered
      >
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Typography.Title
              type={error ? 'danger' : 'success'}
              level={3}
              style={{ textAlign: 'center', paddingTop: 32 }}
            >
              {error ? 'Failure!' : 'Success!'}
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Typography.Paragraph style={{ textAlign: 'center' }}>
              {error
                ? `An error has been occurred: ${error}`
                : 'You have successfully set up your email.'}
            </Typography.Paragraph>
          </Col>
          <Col span={24}>
            <Button
              type="primary"
              size="large"
              onClick={() => {
                if (!error) onComplete()
                return setVisible(false)
              }}
              block
            >
              Gotcha!
            </Button>
          </Col>
        </Row>
      </Modal>
    </Row>
  )
}

export default EmailVerification
