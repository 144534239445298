import icon from './assets/binance.png'
import AdapterInterface, { Chain } from './adapter.interface'

class BinanceEvmAdapter implements AdapterInterface {
  // Private provider
  private provider = window.BinanceChain
  // Public params
  public name = 'Binance'
  public chain = Chain.evm
  public icon = icon
  public url = 'https://www.bnbchain.org/en/binance-wallet'
  public color =
    'linear-gradient(90deg, rgb(218 192 66) 0%, rgb(234 126 0) 100%)'
  public isInstalled = !!this.provider
  // Public functions
  public connect = async () => {
    const [account] =
      (await this.provider?.request({ method: 'eth_requestAccounts' })) || []
    return account
  }
  public install = () => window.open(this.url, '_blank')
  public signMessage = async (msg: string) => {
    const address = await this.getAddress()
    const hex = `0x${Buffer.from(msg, 'utf8').toString('hex')}`
    const sig = await this.provider.request({
      method: 'personal_sign',
      params: [hex, address.toLowerCase()],
    })
    return sig
  }
  public getAddress = async () => {
    const address = await this.connect()
    if (!address) throw new Error('Invalid address')
    return address
  }
  public disconnect = () => {}
}

export default BinanceEvmAdapter
