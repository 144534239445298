import axios from 'axios'

import configs from 'configs'
import storage from 'libs/storage'
import { AccessToken, getAuthorizationHeader } from './accessToken'

const { api, oauth } = configs

export const TWITTER_OAUTH_ORIGIN = 'twitter_oauth_origin'
export const TWITTER_OAUTH_SECRET = 'twitter_oauth_secret'
export const TWITTER_OAUTH_TYPE = 'twitter_oauth_type'

export enum TwitterCredentialType {
  RequestToken = 'request_token',
  ExchangeToken = 'exchange_token',
}

export enum TwitterMethod {
  Login = 'login',
  Connect = 'connect',
}

export type TwitterAuthCredential = {
  type: TwitterCredentialType
  token?: string
  secret?: string
  verifier?: string
}

/**
 * Reuqets client token and secret token
 * @returns
 */
export const requestToken = async (
  type: TwitterMethod = TwitterMethod.Login,
): Promise<{
  token: string
  secret: string
}> => {
  const { data } = await axios.post(`${api.auth}/login-twitter`, {
    type: TwitterCredentialType.RequestToken,
    callbackUri: oauth.twitter.callbackUri,
  })
  storage.set(TWITTER_OAUTH_SECRET, data.secret)
  storage.set(TWITTER_OAUTH_TYPE, type)
  return data
}

/**
 * Login with Twitter
 * @param payload.token
 * @param payload.verifier
 * @returns
 */
export const loginTwitter = async ({
  token,
  verifier,
}: {
  token: string
  verifier: string
}): Promise<AccessToken> => {
  const secret = storage.clear(TWITTER_OAUTH_SECRET)
  const type = storage.clear(TWITTER_OAUTH_TYPE)
  if (type !== TwitterMethod.Login) throw new Error('Invalid method')
  const { data } = await axios.post(`${api.auth}/login-twitter`, {
    type: TwitterCredentialType.ExchangeToken,
    callbackUri: oauth.twitter.callbackUri,
    token,
    secret,
    verifier,
  })
  return data
}

/**
 * Connect with Twitter
 * @param payload.token
 * @param payload.verifier
 * @returns
 */
export const connectTwitter = async ({
  token,
  verifier,
}: {
  token: string
  verifier: string
}): Promise<AccessToken> => {
  const secret = storage.clear(TWITTER_OAUTH_SECRET)
  const type = storage.clear(TWITTER_OAUTH_TYPE)
  if (type !== TwitterMethod.Connect) throw new Error('Invalid method')
  const { data } = await axios.post(
    `${api.auth}/connect-twitter`,
    {
      type: TwitterCredentialType.ExchangeToken,
      callbackUri: oauth.twitter.callbackUri,
      token,
      secret,
      verifier,
    },
    {
      headers: getAuthorizationHeader(),
    },
  )
  return data
}
