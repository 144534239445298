import { Button, Card, Col, Row, Typography } from 'antd'

const Settings = () => {
  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={3}>Settings</Typography.Title>
        <Typography.Paragraph type="secondary">
          Customize your profile for how it appears to other Ancient8 members.
        </Typography.Paragraph>
      </Col>
      <Col span={24}>
        <Card bordered={false}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Typography.Title level={5}>Delete account</Typography.Title>
              <Typography.Paragraph type="secondary">
                (Comming soon) Once you delete your account, all the data will
                be wiped out and cannot be recovered. Are you sure you want to
                delete your account?
              </Typography.Paragraph>
            </Col>
            <Col span={24}>
              <Button type="primary" size="large" danger block disabled>
                Delete Account
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  )
}

export default Settings
