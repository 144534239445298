import { Image } from 'antd'

import ANCIENT8 from 'static/images/ancient8.png'
import SOLANA from 'static/images/solana.svg'
import EVM from 'static/images/evm.png'

export type LogoProps = { width?: number; onClick?: () => void }

export const Ancient8Logo = ({ width = 32, onClick = () => {} }: LogoProps) => {
  return (
    <Image
      style={{ cursor: 'pointer' }}
      src={ANCIENT8}
      preview={false}
      width={width}
      onClick={onClick}
    />
  )
}

export const SolanaLogo = ({ width = 32, onClick = () => {} }: LogoProps) => {
  return (
    <Image
      style={{ cursor: 'pointer' }}
      src={SOLANA}
      preview={false}
      width={width}
      onClick={onClick}
    />
  )
}

export const EvmLogo = ({ width = 32, onClick = () => {} }: LogoProps) => {
  return (
    <Image
      style={{ cursor: 'pointer' }}
      src={EVM}
      preview={false}
      width={width}
      onClick={onClick}
    />
  )
}
