import icon from './assets/coinbase.png'
import AdapterInterface, { Chain } from './adapter.interface'

class CoinbaseEvmAdapter implements AdapterInterface {
  // Private provider
  private provider = window.coinbaseWalletExtension
  // Public params
  public name = 'Coinbase'
  public chain = Chain.evm
  public icon = icon
  public url = 'https://www.coinbase.com/wallet'
  public color =
    'linear-gradient(90deg, rgb(37 137 255) 0%, rgb(29 30 71) 100%)'
  public isInstalled =
    this.provider?.isCoinbaseWallet || this.provider?.isCoinbaseBrowser
  // Public functions
  public connect = async () => {
    const [account] =
      (await this.provider?.request({ method: 'eth_requestAccounts' })) || []
    return account
  }
  public install = () => window.open(this.url, '_blank')
  public signMessage = async (msg: string) => {
    const address = await this.getAddress()
    const hex = `0x${Buffer.from(msg, 'utf8').toString('hex')}`
    const sig = await this.provider.request({
      method: 'personal_sign',
      params: [hex, address.toLowerCase()],
    })
    return sig
  }
  public getAddress = async () => {
    const address = await this.connect()
    if (!address) throw new Error('Invalid address')
    return address
  }
  public disconnect = this.provider?.disconnect
}

export default CoinbaseEvmAdapter
