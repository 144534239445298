import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Menu,
  MenuProps,
  Row,
  Space,
  Typography,
} from 'antd'
import IonIcon from '@sentre/antd-ionicon'
import { Ancient8Logo } from 'components/logos'
import Navigation from './navigation'

import { useOpenConnect } from 'hooks/useUi'
import { useLogout, useUserAvatar, useUserId } from 'hooks/useUser'
import { shortenString } from 'libs/util'

const Header = () => {
  const userId = useUserId()
  const avatar = useUserAvatar()
  const onOpenConnect = useOpenConnect()
  const navigate = useNavigate()
  const onHome = useCallback(() => navigate('/'), [navigate])
  const logout = useLogout()

  const items: MenuProps['items'] = useMemo(
    () => [
      {
        label: 'Profile',
        key: 'profile',
        icon: <IonIcon name="person" />,
        onClick: () => navigate('/profile'),
      },
      {
        type: 'divider',
      },
      {
        label: 'Logout',
        key: 'logout',
        icon: <IonIcon name="log-out-outline" />,
        danger: true,
        onClick: logout,
      },
    ],
    [navigate, logout],
  )

  return (
    <Row gutter={[12, 12]} wrap={false} align="middle">
      <Col>
        <Ancient8Logo width={128} onClick={onHome} />
      </Col>
      <Col flex="auto">
        <Navigation />
      </Col>
      <Col>
        {userId ? (
          <Dropdown
            overlay={<Menu items={items} />}
            placement="bottom"
            trigger={['click']}
          >
            <Button>
              <Space>
                <Typography.Text className="caption">
                  {shortenString(userId, 3)}
                </Typography.Text>
                <Avatar src={avatar} size={16} />
              </Space>
            </Button>
          </Dropdown>
        ) : (
          <Button
            type="primary"
            onClick={onOpenConnect}
            icon={<IonIcon name="wallet" />}
          >
            Log In
          </Button>
        )}
      </Col>
    </Row>
  )
}

export default Header
