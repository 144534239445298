import { useCallback, useEffect, useMemo } from 'react'

import { Button } from 'antd'

import { useCountdown } from 'hooks/useCountdown'
import { EmailState } from '.'

export type SendCodeButtonProps = {
  state: EmailState
  onSendOtp?: () => void
  loading?: boolean
  disabled?: boolean
}

const SendOtpButton = ({
  state,
  onSendOtp = () => {},
  disabled = false,
  loading = false,
}: SendCodeButtonProps) => {
  const { counter, refresh } = useCountdown(60)

  const title = useMemo(() => {
    if (state !== EmailState.VerifyEmail) return 'Send OTP'
    if (!counter) return 'Resend'
    return counter
  }, [state, counter])

  const onClick = useCallback(() => {
    if (state !== EmailState.VerifyEmail) return onSendOtp()
    if (!counter) return onSendOtp()
  }, [state, counter, onSendOtp])

  useEffect(() => {
    if (state === EmailState.VerifyEmail) refresh()
  }, [state, refresh])

  return (
    <Button
      type="link"
      style={{ marginRight: -7 }}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
    >
      {title}
    </Button>
  )
}

export default SendOtpButton
