import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  Avatar,
  Button,
  Card,
  Col,
  Popconfirm,
  Row,
  Space,
  Typography,
} from 'antd'
import IonIcon from '@sentre/antd-ionicon'

import { useClientById } from 'hooks/useClient'
import { useInfix } from 'hooks/useUi'
import { Infix } from 'store/ui.reducer'
import { AppDispatch } from 'store'
import { deleteClient } from 'store/client.reducer'

export type ClientInfoCardProps = { clientId: string }

const ClientInfoCard = ({ clientId }: ClientInfoCardProps) => {
  const [visible, setVisible] = useState(false)
  const dispatch = useDispatch<AppDispatch>()
  const infix = useInfix()
  const { avatar, clientName, clientKey, clientSecret, redirectUri } =
    useClientById(clientId)

  const gap = infix < Infix.sm ? 12 : 24

  const onRemove = useCallback(async () => {
    await dispatch(deleteClient(clientId))
  }, [dispatch, clientId])

  return (
    <Card>
      <Row gutter={[gap, gap]} wrap={false} align="middle">
        <Col>
          <Avatar shape="square" size={64} src={avatar} />
        </Col>
        <Col flex="auto">
          <Space direction="vertical" size={0}>
            <Space>
              <Typography.Text type="secondary">Name:</Typography.Text>
              <Typography.Text>{clientName}</Typography.Text>
            </Space>
            <Space>
              <Typography.Text type="secondary">Key:</Typography.Text>
              <Typography.Text style={{ width: 168 }} copyable ellipsis>
                {clientKey}
              </Typography.Text>
            </Space>
            <Space wrap={true}>
              <Typography.Text type="secondary">Secret:</Typography.Text>
              <Typography.Text style={{ width: 168 }} copyable ellipsis>
                {clientSecret}
              </Typography.Text>
            </Space>
            <Space wrap={true}>
              <Typography.Text type="secondary">ReURI:</Typography.Text>
              <Typography.Text style={{ width: 168 }} copyable ellipsis>
                {redirectUri}
              </Typography.Text>
            </Space>
          </Space>
        </Col>
        <Popconfirm
          open={visible}
          placement="left"
          title="Are you sure to delete this client?"
          onConfirm={onRemove}
          onCancel={() => setVisible(false)}
        >
          <Button
            type="text"
            style={{ position: 'absolute', top: 8, right: 8 }}
            onClick={() => setVisible(true)}
            icon={<IonIcon name="close" />}
          />
        </Popconfirm>
      </Row>
    </Card>
  )
}

export default ClientInfoCard
