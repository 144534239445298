import { Col, Row, Typography } from 'antd'
import { ChainButton } from 'components/baseButton'

import { useUserId } from 'hooks/useUser'
import { shortenString } from 'libs/util'
import { CHAINS } from 'view/connect/home'
import { useVirtualNavigation } from 'hooks/useVirtualRoute'

const Home = () => {
  const id = useUserId()
  const navigate = useVirtualNavigation()

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title
          style={{ textAlign: 'center' }}
          type="secondary"
          level={5}
        >
          ADD WALLET
        </Typography.Title>
      </Col>
      <Col span={24} />
      <Col span={24}>
        <Typography.Paragraph style={{ textAlign: 'center', margin: 0 }}>
          Currently logged into the UID
        </Typography.Paragraph>
        <Typography.Paragraph type="success" style={{ textAlign: 'center' }}>
          {shortenString(id || 'unknown')}
        </Typography.Paragraph>
      </Col>
      <Col span={24}>
        <Typography.Paragraph style={{ textAlign: 'center' }}>
          Please select desired chain below
        </Typography.Paragraph>
      </Col>
      <Col span={24}>
        <Row gutter={[12, 12]}>
          {(Object.keys(CHAINS) as Array<keyof typeof CHAINS>).map((key) => {
            const { color, path, title } = CHAINS[key]
            return (
              <Col key={key} span={24}>
                <ChainButton
                  color={color}
                  onClick={() => navigate(path)}
                  title={title}
                />
              </Col>
            )
          })}
        </Row>
      </Col>
    </Row>
  )
}

export default Home
