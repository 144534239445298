import axios from 'axios'

import { getAuthorizationHeader } from './accessToken'
import configs from 'configs'
import { AuthEntity } from './authType'

const { api } = configs

export type SessionData = any

export type ProfileData = {
  _id?: string
  username?: string
  displayname?: string
  email?: string
  roles?: string[]
  isEmailVerified?: boolean
  isEnabled?: boolean
  createdAt?: string
  updatedAt?: string
  avatar?: string
  lastLoginTime?: string
  session?: SessionData
  accessedSites?: string[]
  __v?: number
}

export type UpdateProfile = {
  username?: string
  displayname?: string
  removeEmail?: boolean
}

/**
 * Get uid profile.
 * @returns
 */
export const getProfile = async () => {
  const { data } = await axios.get(`${api.user}/profile`, {
    headers: getAuthorizationHeader(),
  })
  return data
}

/**
 * Update uid profile.
 * @returns
 */
export const updateProfile = async (profile: UpdateProfile) => {
  const { data } = await axios.patch(`${api.user}/profile`, profile, {
    headers: getAuthorizationHeader(),
  })
  return data
}

export const updateProfileAvatar = async (file: File) => {
  const payload = new FormData()
  payload.append('files', file, file.name)
  const { data } = await axios.post(
    `${api.user}/profile/upload-image`,
    payload,
    {
      headers: getAuthorizationHeader(),
    },
  )
  return data
}

/**
 * Get auth entities.
 * @returns
 */
export const getAuthEntities = async (): Promise<AuthEntity[]> => {
  const { data } = await axios.get(`${api.user}/profile/auth-entities`, {
    headers: getAuthorizationHeader(),
  })
  return data
}

/**
 * Update primary auth entity.
 * @returns
 */
export const makePrimaryAuthEntity = async (
  authEntityId: string,
): Promise<AuthEntity> => {
  const { data } = await axios.post(
    `${api.user}/profile/auth-entities/${authEntityId}/make-primary`,
    {},
    {
      headers: getAuthorizationHeader(),
    },
  )
  return data
}

/**
 * Delete an auth entity.
 * @param authEntityId
 * @returns
 */
export const deleteAuthEntity = async (authEntityId: string) => {
  const { data } = await axios.delete(
    `${api.user}/profile/auth-entities/${authEntityId}`,
    {
      headers: getAuthorizationHeader(),
    },
  )
  return data
}

/**
 * Logout
 * @returns
 */
export const logout = async () => {
  const { data } = await axios.post(
    `${api.auth}/logout`,
    {},
    {
      headers: getAuthorizationHeader(),
    },
  )
  return data
}
