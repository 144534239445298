import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Button, message } from 'antd'

import { AppDispatch } from 'store'
import { updateProfile } from 'store/user.reducer'
import { useAuthEntitiesByType, useUserEmail } from 'hooks/useUser'
import { isEmailAddress } from 'libs/util'
import { AuthType } from 'libs/api/authType'

const RemoveEmailButton = () => {
  const [loading, setLoading] = useState(false)
  const email = useUserEmail()
  const dispatch = useDispatch<AppDispatch>()
  const authEntities = useAuthEntitiesByType(AuthType.EmailOTP) || []

  const { isPrimary } = authEntities.length > 0 ? authEntities[0] : ({} as any)

  const onRemove = useCallback(async () => {
    try {
      setLoading(true)
      await dispatch(updateProfile({ removeEmail: true })).unwrap()
      return message.success('You have removed the method!')
    } catch (er: any) {
      return message.error(er.message)
    } finally {
      return setLoading(false)
    }
  }, [dispatch])

  return (
    <Button
      size="small"
      type="link"
      onClick={onRemove}
      loading={loading}
      disabled={!isEmailAddress(email) || isPrimary}
    >
      Remove
    </Button>
  )
}

export default RemoveEmailButton
