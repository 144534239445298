import { useCallback, useMemo, useState } from 'react'

import { Button, Col, Input, Modal, Row, Space, Typography } from 'antd'
import IonIcon from '@sentre/antd-ionicon'
import SendOtpButton from './sendOtpButton'
import EmailVerification from './emailVerification'
import RemoveEmailButton from './removeEmailButton'
import Primary from '../primary'

import { useUserEmail } from 'hooks/useUser'
import { challengeEmailOtpForSignup, validateEmail } from 'libs/api/emailAuth'
import { isEmailAddress } from 'libs/util'
import { AuthType } from 'libs/api/authType'

export enum EmailState {
  Init,
  NewEmail,
  UpdateEmail,
  VerifyEmail, // Enter OTP code
}

const Email = () => {
  const defaultEmail = useUserEmail()
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [state, setState] = useState(EmailState.Init)

  const disabled = useMemo(
    () => !isEmailAddress(email) || email === defaultEmail,
    [email, defaultEmail],
  )

  const onSendOtp = useCallback(async () => {
    try {
      setLoading(true)
      const existed = await validateEmail(email)
      if (existed) throw new Error(`Email ${email} already exists.`)
      await challengeEmailOtpForSignup(email)
      setError('')
      return setState(EmailState.VerifyEmail)
    } catch (er: any) {
      return setError(er.message)
    } finally {
      return setLoading(false)
    }
  }, [email])

  const onCleanUp = useCallback(() => {
    setError('')
    setEmail('')
    return setState(EmailState.Init)
  }, [])

  const action = useMemo(() => {
    if (state === EmailState.VerifyEmail || !disabled)
      return (
        <Button size="small" type="link" onClick={onCleanUp}>
          Cancel
        </Button>
      )
    if (isEmailAddress(defaultEmail)) return <RemoveEmailButton />
  }, [state, disabled, defaultEmail, onCleanUp])

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Row gutter={[12, 12]} wrap={false} align="middle">
          <Col flex="auto">
            <Space>
              <Typography.Title level={5}>Email</Typography.Title>
              <Primary type={AuthType.EmailOTP} />
            </Space>
          </Col>
          <Col>{action}</Col>
        </Row>
      </Col>
      <Col span={24}>
        <Input
          placeholder="Enter Email"
          size="large"
          value={email || defaultEmail}
          onChange={(e) => setEmail(e.target.value)}
          suffix={
            <SendOtpButton
              state={state}
              disabled={disabled}
              loading={loading}
              onSendOtp={onSendOtp}
            />
          }
        />
      </Col>
      {state === EmailState.VerifyEmail && (
        <Col span={24}>
          <EmailVerification email={email} onComplete={onCleanUp} />
        </Col>
      )}
      <Modal
        open={!!error}
        footer={null}
        onCancel={() => setError('')}
        closeIcon={<IonIcon name="close" />}
        centered
      >
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Typography.Title
              type="danger"
              level={3}
              style={{ textAlign: 'center', paddingTop: 32 }}
            >
              Failure!
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Typography.Paragraph style={{ textAlign: 'center' }}>
              {`An error has been occurred: ${error}`}
            </Typography.Paragraph>
          </Col>
          <Col span={24}>
            <Button
              type="primary"
              size="large"
              onClick={() => setError('')}
              block
            >
              Gotcha!
            </Button>
          </Col>
        </Row>
      </Modal>
    </Row>
  )
}

export default Email
