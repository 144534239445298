import axios from 'axios'

import configs from 'configs'
import { getAuthorizationHeader } from 'libs/api/accessToken'

const { api } = configs

export type ClientData = {
  _id: string
  createdBy: string
  clientKey: string
  clientName: string
  clientSecret: string
  hostname: string
  redirectUri: string
  avatar: string
  sessionExpiresDelta: string
  createdAt: string
  updatedAt: string
  __v: number
}

export type CreateClient = Pick<
  ClientData,
  'clientName' | 'hostname' | 'redirectUri' | 'avatar' | 'sessionExpiresDelta'
>

/**
 * Get all my clients (based on jwt token)
 * @returns
 */
export const getClients = async () => {
  const { data } = await axios.get(api.client, {
    headers: getAuthorizationHeader(),
  })
  return data
}

/**
 * Get all my client name (based on jwt token)
 * @param clientKey
 * @returns
 */
export const getClientInfo = async (clientKey: string) => {
  const { data } = await axios.get(`${api.client}/${clientKey}`, {
    headers: getAuthorizationHeader(),
  })
  return data
}

/**
 * Create a client
 * @param client
 * @returns
 */
export const createClient = async (client: CreateClient) => {
  const { data } = await axios.post(api.client, client, {
    headers: getAuthorizationHeader(),
  })
  return data
}

/**
 * Delete a client
 * @param clientId
 * @returns
 */
export const deleteClient = async (clientId: string) => {
  const { data } = await axios.delete(`${api.client}/${clientId}`, {
    headers: getAuthorizationHeader(),
  })
  return data
}
