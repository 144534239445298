import { useSelector } from 'react-redux'
import { AppState } from 'store'

export const useClients = () => {
  const clients = useSelector(({ client }: AppState) => client)
  return clients
}

export const useClientById = (clientId: string) => {
  const client = useSelector(({ client }: AppState) => client[clientId])
  return client
}
