import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AuthEntity } from 'libs/api/authType'

import { connectEmail as connectEmailApi } from 'libs/api/emailAuth'
import { connectWallet as connectWalletApi } from 'libs/api/walletAuth'
import { connectDiscord as connectDiscordApi } from 'libs/api/discordAuth'
import { connectTwitter as connectTwitterApi } from 'libs/api/twitterAuth'
import {
  getProfile as getProfileApi,
  updateProfile as updateProfileApi,
  deleteAuthEntity as deleteAuthEntityApi,
  ProfileData,
  UpdateProfile,
  getAuthEntities,
  makePrimaryAuthEntity as makePrimaryAuthEntityApi,
} from 'libs/api/profile'

export type UserState = {
  profile: ProfileData
  authEntities: AuthEntity[]
}

/**
 * Store constructor
 */

const NAME = 'user'
const initialState: UserState = {
  profile: {},
  authEntities: [],
}

/**
 * Actions
 */

export const getProfile = createAsyncThunk(`${NAME}/getProfile`, async () => {
  const profile = await getProfileApi()
  const authEntities = await getAuthEntities()
  return { profile, authEntities }
})

export const updateProfile = createAsyncThunk(
  `${NAME}/updateProfile`,
  async (profile: UpdateProfile) => {
    const data = await updateProfileApi(profile)
    return { profile: data }
  },
)

export const connectEmail = createAsyncThunk(
  `${NAME}/connectEmail`,
  async (payload: Parameters<typeof connectEmailApi>[0]) => {
    const data = await connectEmailApi(payload)
    return { profile: data }
  },
)

export const connectWallet = createAsyncThunk<
  Partial<UserState>,
  Parameters<typeof connectWalletApi>[0],
  { state: any }
>(`${NAME}/connectWallet`, async (payload, { getState }) => {
  const {
    user: { authEntities: prevAuthEntities },
  } = getState()
  const data = await connectWalletApi(payload)
  const authEntities = [...prevAuthEntities, data]
  return { authEntities }
})

export const connectDiscord = createAsyncThunk<
  Partial<UserState>,
  Parameters<typeof connectDiscordApi>[0],
  { state: any }
>(`${NAME}/connectDiscord`, async (payload, { getState }) => {
  const {
    user: { authEntities: prevAuthEntities },
  } = getState()
  const data = await connectDiscordApi(payload)
  const authEntities = [...prevAuthEntities, data]
  return { authEntities }
})

export const connectTwitter = createAsyncThunk<
  Partial<UserState>,
  Parameters<typeof connectTwitterApi>[0],
  { state: any }
>(`${NAME}/connectTwitter`, async (payload, { getState }) => {
  const {
    user: { authEntities: prevAuthEntities },
  } = getState()
  const data = await connectTwitterApi(payload)
  const authEntities = [...prevAuthEntities, data]
  return { authEntities }
})

export const makePrimaryAuthEntity = createAsyncThunk<
  Partial<UserState>,
  string,
  { state: any }
>(`${NAME}/makePrimaryAuthEntity`, async (authEntityId, { getState }) => {
  const {
    user: { authEntities: prevAuthEntities },
  } = getState()
  const data = await makePrimaryAuthEntityApi(authEntityId)
  const authEntities = prevAuthEntities.map((authEntity: AuthEntity) => ({
    ...authEntity,
    isPrimary: authEntity._id === data._id,
  }))
  return { authEntities }
})

export const deleteAuthEntity = createAsyncThunk<
  Partial<UserState>,
  string,
  { state: any }
>(`${NAME}/deleteAuthEntity`, async (authEntityId, { getState }) => {
  const {
    user: { authEntities: prevAuthEntities },
  } = getState()
  await deleteAuthEntityApi(authEntityId)
  const authEntities = prevAuthEntities.filter(
    ({ _id }: AuthEntity) => _id !== authEntityId,
  )
  return { authEntities }
})

/**
 * Usual procedure
 */

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    void builder
      .addCase(
        getProfile.fulfilled,
        (state, { payload }) => void Object.assign(state, payload),
      )
      .addCase(
        updateProfile.fulfilled,
        (state, { payload }) => void Object.assign(state, payload),
      )
      .addCase(
        connectEmail.fulfilled,
        (state, { payload }) => void Object.assign(state, payload),
      )
      .addCase(
        connectWallet.fulfilled,
        (state, { payload }) => void Object.assign(state, payload),
      )
      .addCase(
        connectDiscord.fulfilled,
        (state, { payload }) => void Object.assign(state, payload),
      )
      .addCase(
        connectTwitter.fulfilled,
        (state, { payload }) => void Object.assign(state, payload),
      )
      .addCase(
        makePrimaryAuthEntity.fulfilled,
        (state, { payload }) => void Object.assign(state, payload),
      )
      .addCase(
        deleteAuthEntity.fulfilled,
        (state, { payload }) => void Object.assign(state, payload),
      ),
})

export default slice.reducer
