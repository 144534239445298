import { ReactElement, useEffect } from 'react'

import { usePath } from 'hooks/useVirtualRoute'
import { parseVirtualRoute, replace } from 'store/virtualRoute.reducer'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'

export type VirtualRedirectProps = {
  from: string
  to: string
}

export const VirtualRedirect = ({ from, to }: VirtualRedirectProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const currentPath = usePath()
  useEffect(() => {
    if (from === currentPath) dispatch(replace(to))
  }, [dispatch, currentPath, from, to])
  return null
}

export type VirtualRouteProps = {
  exact?: boolean
  route: string
  children: ReactElement
}

export const VirtualRoute = ({
  exact = false,
  route,
  children,
}: VirtualRouteProps) => {
  const currentPath = usePath()
  const { path } = parseVirtualRoute(route)
  if (currentPath === path) return children
  else if (!exact && currentPath.startsWith(path)) return children
  else return null
}
